import React from 'react';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';

export type TSearchBoxProps = {
  label?: string;
  required?: boolean;
  onChange?: (query: string) => void;
  debounce?: number;
};

export const SearchTracking: React.FC<TSearchBoxProps> = (props) => {
  const searchValue = _.debounce(
    (value) => (props.onChange ? props.onChange(value) : value),
    props.debounce || 0,
  );

  return (
    <TextField
      // label={label}
      variant="outlined"
      placeholder={props.label ? props.label : 'Search for a title'}
      sx={{
        '& .MuiFormLabel-asterisk': {
          color: '#213B54',
        },
        input: {
          color: '#213B54',
          paddingLeft: '12px',
          '&::placeholder': {
            fontSize: '15px',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.5px',
            opacity: 1,
          },
        },
        backgroundColor: '#EEF1F3',
        borderRadius: '28px',
        width: '360px',
        height: '56px',
        color: '#213B54',
        fontWeight: 500,
        fontSize: '15px',
        zIndex: 1,
        lineHeight: '24px',
        letterSpacing: '0.5px',
        border: 'none',
        fieldset: {
          border: 'none',
        },
      }}
      required={props.required}
      InputProps={{
        startAdornment: (
          <SearchIcon
            sx={{
              color: '#213B54',
            }}
          />
        ),
      }}
      onChange={(e) => searchValue(e.target.value)}
    />
  );
};
