import { DASHBOARD_ROUTES } from '../../Routes';
import { Link } from 'react-router-dom';
import React from 'react';

export const NotFoundPage: React.FC = () => {
  return (
    <div className="not-found text-center w-full">
      <h3 className="m-6 text-lg">Not found</h3>
      <button className="bg-white text-secondary py-[10px] px-4 rounded border">
        <Link to={DASHBOARD_ROUTES.COMPANY_OVERVIEW}>Home</Link>
      </button>
    </div>
  );
};
