import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { IProvider, IProviderListRoot } from 'data/models/provider.model';
import { getProviderById, getProviders } from 'data/api/provider.api';
import { IListRequest } from 'data/models/common.model';

export interface ProviderState {
  value?: IProvider;
  status: 'idle' | 'loading' | 'failed';
  values?: IProviderListRoot;
}

const initialState: ProviderState = {
  value: undefined,
  status: 'idle',
  values: undefined,
};

export const fetchProvidersAsync = createAsyncThunk(
  'providers/fetchProvidersStatus',
  async (params: IListRequest) => {
    const response = await getProviders(params);
    return response;
  },
);
export const fetchProviderByIdAsync = createAsyncThunk(
  'providers/fetchProviderByIdStatus',
  async (providerId: string) => {
    const response = await getProviderById(providerId);
    return response;
  },
);

export const providerSlice = createSlice({
  name: 'provider',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchProvidersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProvidersAsync.fulfilled, (state, action) => {
        state.values = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchProvidersAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchProviderByIdAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProviderByIdAsync.fulfilled, (state, action) => {
        state.value = action.payload.data as IProvider;
        state.status = 'idle';
      })
      .addCase(fetchProviderByIdAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
  reducers: {
    setProvider: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { setProvider } = providerSlice.actions;
export const selectProvider = (state: RootState) => state.provider.value;
export const selectProviderStatus = (state: RootState) => state.provider.status;
export const selectProviders = (state: RootState) => state.provider.values;
export default providerSlice.reducer;
