import React, { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import Button from 'components/Button';
import Select from 'components/Select';
import {
  ITemplateTaskListForm,
  RelationshipType,
} from 'data/models/template-task-list.model';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTemplateTaskList } from 'pages/template-task-list/layouts';
import { API_ROUTES } from 'data/ApiRoutes';
import { AxiosError, AxiosResponse } from 'axios';
import { GET } from 'data/services/api';
import { toast } from 'react-toastify';
import { DASHBOARD_ROUTES } from 'Routes';
import { formatterToCaps } from 'utils';
import {
  createTemplateTaskList,
  updateTemplateTaskList,
} from 'data/api/template-task-list.api';
import CheckboxSingle from 'components/CheckboxSingle';
import TextField from 'components/TextField';
interface MemberEvent {
  eventId: string;
  name: string;
}
interface MemberEventListResponse {
  events: MemberEvent[] | null;
}

const NavigationRole = [
  'I am the executor',
  'I am helping the executor',
  `I'm not the executor but I want to learn more`,
  `I’m their employer helping with logistics`,
  `I don't need any help with managing logistics`,
];

export const DetailFormTaskList = () => {
  const { templateTaskList } = useTemplateTaskList();
  const [eventList, setEventList] = useState<MemberEventListResponse>();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, clearErrors, watch } =
    useForm<ITemplateTaskListForm>({
      reValidateMode: 'onChange',
    });

  const [disabledEvent, setDisabledEvent] = useState(false);
  const getMemberEventList = async () => {
    try {
      const data: AxiosResponse<MemberEventListResponse> = await GET(
        API_ROUTES.getMemberEventList,
      );
      if (data.data) {
        setEventList(data.data);
      }
      return data.data;
    } catch (e: unknown) {
      if (typeof e === 'string') {
        throw Error(e.toUpperCase());
      } else if (e instanceof Error) {
        throw Error(e.message);
      }
      throw Error('');
    }
  };
  const resetForm = () => {
    reset({
      audience: templateTaskList?.audience || '',
      relatedRoleRelationship: templateTaskList?.relatedRoleRelationship || '',
      eventType: templateTaskList?.eventType || '',
      deathDateRange: templateTaskList?.deathDateRange || '',
      navigationRole: templateTaskList?.navigationRole || '',
      includeDueDates: templateTaskList?.includeDueDates || false,
      templateName: templateTaskList?.templateName || '',
    });
    clearErrors();
  };

  const handleAllValid = async (values: ITemplateTaskListForm) => {
    try {
      let result;
      if (!templateTaskList?.id) {
        result = await createTemplateTaskList(values);
      } else {
        result = await updateTemplateTaskList({
          ...values,
          id: templateTaskList?.id,
        });
      }
      if (!result?.success) {
        throw new Error(result?.message);
      }
      toast.success(
        !templateTaskList?.id
          ? 'Add template task list successfully'
          : 'Update template task list successfully',
      );
      navigate(DASHBOARD_ROUTES.ROUTE_TASK_LIST);
    } catch (err) {
      const error = err as Error | AxiosError;
      if (templateTaskList?.id) {
        toast.error(
          formatterToCaps(error.message) || 'Update template task list failed!',
        );
      } else {
        toast.error(
          formatterToCaps(error.message) || 'Create template task list failed!',
        );
      }
      console.error('Error: ', error);
    }
  };

  React.useEffect(() => {
    resetForm();
  }, [templateTaskList]);

  React.useEffect(() => {
    getMemberEventList();
  }, []);

  React.useEffect(() => {
    if (watch('audience') === 'Member') {
      setDisabledEvent(true);
    }
  }, [watch('audience')]);

  return (
    <form onSubmit={handleSubmit(handleAllValid)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction={{ sm: 'row', xs: 'column-reverse' }}
            justifyContent="end"
            alignItems="center"
            width="full"
            spacing={2}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                width: { xs: '100%', sm: 82 },
              }}
            >
              Save
            </Button>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckboxSingle name="includeDueDates" control={control} />
          Include Due Dates
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="templateName"
            label="Name"
            control={control}
            required
            rules={{ required: 'Name is required.' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="audience"
            control={control}
            label="Audience"
            showSelected={true}
            required
            data={[
              {
                value: 'Employer',
                label: 'Employer',
              },
              {
                value: 'Hospice',
                label: 'Hospice',
              },
              {
                value: 'Member',
                label: 'Member',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="relatedRoleRelationship"
            label="Relationship"
            control={control}
            showSelected={true}
            data={Object.values(RelationshipType).map((val) => ({
              value: val,
              label: val,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="eventType"
            label="Event"
            control={control}
            showSelected={true}
            disabled={disabledEvent}
            data={
              eventList?.events?.map((val: MemberEvent) => ({
                value: val.eventId,
                label: val.name,
              })) || []
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="deathDateRange"
            control={control}
            label="Death Date Range"
            showSelected={true}
            data={[
              {
                value: 'Within The Last Week',
                label: 'Within The Last Week',
              },
              {
                value: 'Within The Last Month',
                label: 'Within The Last Month',
              },
              {
                value: 'Within The Last Year',
                label: 'Within The Last Year',
              },
              {
                value: 'More Than A Year Ago',
                label: 'More Than A Year Ago',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="navigationRole"
            label="Navigation Role"
            showSelected={true}
            control={control}
            data={NavigationRole.map((val) => ({
              value: val,
              label: val,
            }))}
          />
        </Grid>
      </Grid>
    </form>
  );
};
