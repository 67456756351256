import { CommonResponse, IListRequest } from 'data/models/common.model';
import {
  ITemplateTaskList,
  ITemplateTaskListForm,
  ITemplateTaskListRoot,
} from 'data/models/template-task-list.model';
import instance from '../services/api';
import { AxiosResponse } from 'axios';
import { buildApiPath } from 'utils';
import { API_ROUTES } from 'data/ApiRoutes';

export const getTemplateTaskList = async (params: IListRequest) => {
  try {
    const data: AxiosResponse<ITemplateTaskListRoot> = await instance.get(
      buildApiPath({
        path: API_ROUTES.templateTaskList,
        params,
      }),
    );
    return data.data;
  } catch (error: unknown) {
    if (typeof error === 'string') {
      throw Error(error.toUpperCase());
    } else if (error instanceof Error) {
      throw Error(error.message);
    }
    throw Error('');
  }
};

export const getTemplateTaskListById = async (templateTaskListId: string) => {
  try {
    const data: AxiosResponse<CommonResponse<ITemplateTaskList>> =
      await instance.get(
        buildApiPath({
          path: API_ROUTES.templateTaskListById(templateTaskListId),
          params: null,
        }),
      );

    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};

export const createTemplateTaskList = async (
  payload: ITemplateTaskListForm,
) => {
  try {
    const data: AxiosResponse<CommonResponse<ITemplateTaskList>> =
      await instance.post(API_ROUTES.createTemplateTaskList, payload);
    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};

export const updateTemplateTaskList = async (
  payload: ITemplateTaskListForm,
) => {
  try {
    const data: AxiosResponse<CommonResponse<ITemplateTaskListForm>> =
      await instance.post(API_ROUTES.editTemplateTaskList, payload);

    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};
