/* eslint-disable @typescript-eslint/no-empty-function */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';

type TModalCustomProps = {
  modal: boolean;
  title: string;
  groupButton: ReactNode;
  subTittle: string;
  handleClose: () => void;
};

const ModalCustom = ({
  title,
  subTittle,
  modal,
  groupButton,
  handleClose = () => {},
}: TModalCustomProps) => {
  const theme = useTheme();
  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      PaperProps={{ sx: { width: '500px', borderRadius: 7, padding: 3 } }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          padding: 0,
          fontWeight: 700,
          fontSize: '21px',
          lineHeight: '26,64px',
          color: '#213B54',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
          marginTop: 2,
          fontWeight: 500,
          fontSize: '14px',
          color: '#213B54',
          lineHeight: '20px',
        }}
      >
        <DialogContentText
          id="alert-dialog-description"
          sx={{ color: theme.palette.primary.main }}
        >
          {subTittle}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: 0, marginTop: 3 }}>
        {groupButton}
      </DialogActions>
    </Dialog>
  );
};

export default ModalCustom;
