import { SignedPutUrl } from '@data/models/signed-put-rl.model';
import { Grid, Stack, Typography, styled } from '@mui/material';
import { DASHBOARD_ROUTES } from 'Routes';
import { AxiosError } from 'axios';
import Button from 'components/Button';
import CheckboxSingle from 'components/CheckboxSingle';
import MultiSelect from 'components/MultiSelect';
import Select from 'components/Select';
import TextField from 'components/TextField';
import Textarea from 'components/Textarea';
import {
  createProvider,
  getSignedPutUrl,
  updateProvider,
  uploadLogoProviderToAWS,
} from 'data/api/provider.api';
import {
  ETHNICITY,
  GENDERS,
  INSURANCE,
  LANGUAGES,
  MODALITIES,
  PROVIDER_STATES,
  PROVIDERTYPES,
} from 'data/constants/static';
import { IProviderForm } from 'data/models/provider.model';
import moment from 'moment';
import { useProvider } from 'pages/provider/layouts';
import React, { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatterToCaps } from 'utils';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const DetailFormProvider = () => {
  const { provider } = useProvider();

  const navigate = useNavigate();
  const { control, handleSubmit, reset, clearErrors } = useForm<IProviderForm>({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const [photoUrl, setPhotoUrl] = React.useState<string | undefined>(
    provider?.photo,
  );
  const [file, setFile] = React.useState<File>();

  const resetForm = () => {
    const lastModifiedDate =
      provider?.lastModifiedDate || provider?.createdDate
        ? moment(provider?.lastModifiedDate || provider?.createdDate).format(
            'MM/DD/YYYY h:mm a',
          )
        : '';
    const createdDate = provider?.createdDate
      ? moment(provider?.createdDate).format('MM/DD/YYYY h:mm a')
      : '';
    reset({
      providerId: provider?.providerId || '',
      providerCategoryId: provider?.providerCategoryId || '',
      name: provider?.name || '',
      description: provider?.description || '',
      providerDescription: provider?.providerDescription || '',
      canPrescribe: provider?.canPrescribe || false,
      createdDate,
      providerType: provider?.providerType || PROVIDERTYPES[0],
      title: provider?.title,
      gender: provider?.gender || GENDERS[0],
      modalities: provider?.modalities || [],
      insurance: provider?.insurance || [],
      experience: provider?.experience || '',
      education: provider?.education || '',
      specialty1: provider?.specialty1 || '',
      specialty2: provider?.specialty2 || '',
      specialty3: provider?.specialty3 || '',
      state:
        provider?.state && provider?.state.length
          ? PROVIDER_STATES.filter((f) =>
              provider?.state?.includes(f.abbreviation),
            ).map((f) => f.abbreviation)
          : PROVIDER_STATES.map((st) => st.abbreviation),
      languages:
        provider?.languages && provider?.languages.length
          ? provider?.languages
          : ['English'],
      ethnicity: provider?.ethnicity || [],
      scheduleUrl: provider?.scheduleUrl || '',
      photoUrl: provider?.photo || '',
      lastModifiedDate,
    });
    clearErrors();
  };

  const handleAllValid = async (values: IProviderForm) => {
    try {
      let result;

      const data = {
        providerId: values?.providerId,
        userId: values?.userId || provider?.userId || '',
        providerCategoryId: values?.providerCategoryId,
        name: values?.name,
        description: values?.description,
        providerDescription: values?.providerDescription,
        canPrescribe: values?.canPrescribe,
        createdDate: values?.createdDate,
        providerType: values?.providerType,
        title: values?.title,
        gender: values?.gender,
        modalities: values?.modalities,
        insurance: values?.insurance,
        experience: values?.experience,
        education: values?.education,
        specialty1: values?.specialty1,
        specialty2: values?.specialty2,
        specialty3: values?.specialty3,
        state: values?.state,
        languages: values?.languages,
        ethnicity: values?.ethnicity,
        scheduleUrl: values?.scheduleUrl,
        photoUrl: provider?.providerId ? photoUrl : '',
        lastModifiedDate: values?.lastModifiedDate,
      };
      if (!provider?.providerId) {
        await createProvider(data)
          .then((data: any) => {
            if (data?.data && file) {
              uploadLogoForCreateProviderToAws(data?.data?.providerId);
            }
            toast.success('Add provider successfully');
            navigate(DASHBOARD_ROUTES.ROUTE_PROVIDER);
          })
          .catch((error) => {
            console.error(error);
            toast.error('Create provider fail!');
          });
      } else {
        result = await updateProvider(data);
        if (!result?.success) {
          throw new Error(result?.message);
        }
        toast.success('Update provider successfully');
        navigate(DASHBOARD_ROUTES.ROUTE_PROVIDER);
      }
    } catch (err) {
      const error = err as Error | AxiosError;
      if (provider?.providerId) {
        toast.error(
          formatterToCaps(error.message) || 'Update provider failed!',
        );
      } else {
        toast.error(
          formatterToCaps(error.message) || 'Create provider failed!',
        );
      }
      console.error('Error: ', error);
    }
  };

  React.useEffect(() => {
    resetForm();
  }, [provider]);

  const handleUploadLogo = async (event: ChangeEvent) => {
    const files = (event.target as HTMLInputElement).files;
    if (files?.length) {
      const fileCurrent = files[0];
      const ACCEPT_TYPES = ['image/png', 'image/jpeg'];
      if (!ACCEPT_TYPES.includes(fileCurrent.type)) {
        toast.error(
          'This file type is not supported. The following file types are supported: jpeg, png!',
        );
        return;
      }

      const FILE_MAX_SIZES = 5;
      const isLimitSize = fileCurrent.size / 1024 / 1024 < FILE_MAX_SIZES;
      if (!isLimitSize) {
        toast.error(
          `The file size is too large. The maximum file size is ${FILE_MAX_SIZES}MB.`,
        );
        return;
      }

      try {
        if (provider?.providerId) {
          setFile(fileCurrent);
          const dataSignedPut: SignedPutUrl = await getSignedPutUrl(
            provider?.providerId,
            fileCurrent.type,
          );
          await uploadLogoProviderToAWS(
            dataSignedPut.signedPutUrl,
            fileCurrent,
          );
          setPhotoUrl(dataSignedPut.userPhotoUrl);
          toast.success('Upload success!');
        } else {
          setPhotoUrl(URL.createObjectURL(fileCurrent));
          setFile(fileCurrent);
        }
      } catch (error) {
        console.error(error);
        toast.error('Upload fail!');
      }
    }
  };

  const uploadLogoForCreateProviderToAws = async (providerId: string) => {
    if (providerId && file) {
      const dataSignedPut: SignedPutUrl = await getSignedPutUrl(
        providerId,
        file.type,
      );
      await uploadLogoProviderToAWS(dataSignedPut.signedPutUrl, file);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleAllValid)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction={{ sm: 'row', xs: 'column-reverse' }}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <CheckboxSingle
              name="canPrescribe"
              label="Show to Members"
              control={control}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                width: { xs: '100%', sm: 82 },
              }}
            >
              Save
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="name"
            label="Name"
            control={control}
            required
            rules={{ required: 'Name is required.' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="title"
            label="Title"
            control={control}
            required
            rules={{ required: 'Title is required.' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="providerType"
            label="Provider Type"
            control={control}
            showSelected={true}
            data={PROVIDERTYPES.map((item) => ({
              value: item,
              label: item,
            }))}
            required
            rules={{ required: 'Provider Type is required.' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="gender"
            label="Gender"
            control={control}
            data={GENDERS.map((item) => ({
              value: item,
              label: item,
            }))}
            required
            rules={{ required: 'Gender is required.' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultiSelect
            name="modalities"
            label="Modalities"
            control={control}
            data={MODALITIES.sort().map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultiSelect
            name="insurance"
            label="Insurance"
            control={control}
            data={INSURANCE.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </Grid>
        <Grid item xs={12}>
          <Textarea
            name="experience"
            label="Experience"
            control={control}
            minRows={2}
          />
        </Grid>
        <Grid item xs={12}>
          <Textarea
            name="education"
            label="Education"
            control={control}
            minRows={2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="specialty1" label="Specialty#1" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultiSelect
            name="state"
            label="State"
            control={control}
            data={PROVIDER_STATES.map((item) => ({
              value: item.abbreviation,
              label: item.name,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="specialty2" label="Specialty#2" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultiSelect
            name="languages"
            label="Languages"
            control={control}
            data={LANGUAGES.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="specialty3" label="Specialty#3" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultiSelect
            name="ethnicity"
            label="Ethnicity"
            control={control}
            data={ETHNICITY.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="scheduleUrl"
            label="Schedule URL"
            control={control}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginBottom: provider?.providerId ? '0' : '16px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '15px',
              lineHeight: '24px',
              letterSpacing: '0.5px',
              color: 'black',
            }}
          >
            Profile Photo
          </Typography>
          <Button
            component="label"
            sx={{
              width: '220px',
              height: '150px !important',
              color: '#000000 !important',
              background: '#D9D9D97A !important',
              border: '2px solid #e9ebef',
              borderRadius: '4px !important',
              cursor: 'pointer',
              margin: '3px 0 0 ',
              textAlign: 'center',
              fontWeight: 500,
            }}
          >
            {photoUrl ? (
              <img
                key={Date.now()}
                src={`${photoUrl}`}
                style={{
                  display: 'block',
                  height: '150px',
                  width: '220px',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <>Upload file</>
            )}

            <VisuallyHiddenInput
              onChange={handleUploadLogo}
              name="photoUrl"
              type="file"
            />
          </Button>
        </Grid>
        {provider?.providerId && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                marginBottom: { xs: '0px', sm: '16px' },
              }}
            >
              <TextField
                name="createdDate"
                placeholder=""
                label="Created Date"
                control={control}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                marginBottom: '16px',
              }}
            >
              <TextField
                name="lastModifiedDate"
                placeholder=""
                label="Last Modified Date"
                control={control}
                disabled
              />
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};
