import React, { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import Button from 'components/Button';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { useTemplateTask } from 'pages/template-task/layouts';
import { ITemplateTask } from '@data/models/template-task.model';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CheckboxSingle from 'components/CheckboxSingle';
import { toast } from 'react-toastify';
import { DASHBOARD_ROUTES } from 'Routes';
import { formatterToCaps } from 'utils';
import {
  createTemplateTask,
  updateTemplateTask,
} from 'data/api/template-task.api';
import { AxiosError, AxiosResponse } from 'axios';
import { API_ROUTES } from 'data/ApiRoutes';
import { GET } from 'data/services/api';
import { useAppSelector } from 'store/hooks';
import { fetchTemplateTaskListAsync } from 'store/templateTaskListSlide';
import { useDispatch } from 'react-redux';
import RichTextEditorField from 'components/RichTextEditorField';
import * as DOMPurify from 'dompurify';

export enum TaskStatusEnum {
  NotStarted = 'Not Started',
  Confirmed = 'Confirmed',
  InProgress = 'In Progress',
  Complete = 'Complete',
  Incomplete = 'Incomplete',
}

export enum MailTypeEnum {
  USPSFirstClass = 'USPS First Class',
  USPSStandard = 'USPS Standard',
}

interface CommunicationTemplate {
  id: string;
  templateName?: string | undefined;
  templateType?: string | undefined;
}
interface CommunicationTemplateListResponse {
  communicationTemplates: CommunicationTemplate[] | null;
}

export const DetailFormTask = () => {
  const [communicationTemplates, setCommunicationTemplates] =
    useState<CommunicationTemplateListResponse>();
  const { templateTask } = useTemplateTask();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, clearErrors, watch } =
    useForm<ITemplateTask>({
      reValidateMode: 'onChange',
    });
  const dispatch = useDispatch();
  const templateTaskList = useAppSelector(
    (state) => state.templateTaskList.values,
  );

  const resetForm = (dueDateLogic: string) => {
    reset({
      id: templateTask?.id,
      templateTaskListsId: templateTask?.templateTaskListsId,
      timeCategory: templateTask?.timeCategory || '',
      taskName: templateTask?.taskName || '',
      assignedToLogic: templateTask?.assignedToLogic || '',
      dueDateLogic: templateTask?.dueDateLogic || dueDateLogic,
      detail: templateTask?.detail || '',
      orderIndex: templateTask?.orderIndex || 0,
      templateTaskType: templateTask?.templateTaskType || '',
      templateTaskStatus: templateTask?.templateTaskStatus || '',
      sendTo: templateTask?.sendTo || '',
      mailType: templateTask?.mailType || '',
      communicationTemplate: templateTask?.communicationTemplate || '',
      taskCategory: templateTask?.taskCategory || '',
      showFindProviderCardLarge:
        templateTask?.showFindProviderCardLarge || false,
      dueDateType: templateTask?.dueDateType || '',
    });
    clearErrors();
  };

  const getCommunicationTemplates = async () => {
    try {
      const data: AxiosResponse<CommunicationTemplateListResponse> = await GET(
        API_ROUTES.getCommunicationTemplates,
      );
      if (data.data) {
        setCommunicationTemplates(data.data);
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        throw Error(e.toUpperCase());
      } else if (e instanceof Error) {
        throw Error(e.message);
      }
      throw Error('');
    }
  };

  const handleAllValid = async (values: ITemplateTask) => {
    try {
      if (values.detail) {
        values.detail = DOMPurify.sanitize(values.detail);
      }
      let result;
      if (!templateTask?.id) {
        result = await createTemplateTask(values);
      } else {
        result = await updateTemplateTask(values);
      }
      if (!result?.success) {
        throw new Error(result?.message);
      }
      toast.success(
        !templateTask?.id
          ? 'Add template task successfully'
          : 'Update template task successfully',
      );
      navigate(DASHBOARD_ROUTES.ROUTE_TASK);
    } catch (err) {
      const error = err as Error | AxiosError;
      if (templateTask?.id) {
        toast.error(
          formatterToCaps(error.message) || 'Update template task failed!',
        );
      } else {
        toast.error(
          formatterToCaps(error.message) || 'Create template task failed!',
        );
      }
      console.error('Error: ', error);
    }
  };

  React.useEffect(() => {
    resetForm('0');
  }, [templateTask]);

  React.useEffect(() => {
    getCommunicationTemplates();
  }, []);

  React.useEffect(() => {
    dispatch(
      fetchTemplateTaskListAsync({
        sortBy: 'last_modified_date',
        order: 'desc',
        page: 0,
        pageSize: 1000,
      }),
    );
  }, [
    JSON.stringify({
      sortBy: 'last_modified_date',
      order: 'desc',
      page: 0,
      pageSize: 1000,
    }),
  ]);

  return (
    <form onSubmit={handleSubmit(handleAllValid)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction={{ sm: 'row', xs: 'column-reverse' }}
            justifyContent="end"
            alignItems="center"
            width="full"
            spacing={2}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                width: { xs: '100%', sm: 82 },
              }}
            >
              Save
            </Button>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckboxSingle name="showFindProviderCardLarge" control={control} />
          Show Find Provider Card Large
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            name="taskName"
            label="Task Name"
            control={control}
            required
            rules={{ required: 'Task Name is required.' }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            name="templateTaskType"
            control={control}
            label="Template Type"
            showSelected={true}
            required
            rules={{ required: 'Template Type is required.' }}
            data={[
              {
                value: 'To-Do',
                label: 'To-Do',
              },
              {
                value: 'Email',
                label: 'Email',
              },
              {
                value: 'Letter',
                label: 'Letter',
              },
              {
                value: 'Card',
                label: 'Card',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            name="assignedToLogic"
            control={control}
            label="Assigned To Logic"
            showSelected={true}
            required
            rules={{ required: 'Assigned To is required.' }}
            data={[
              {
                value: 'Betterleave',
                label: 'Betterleave',
              },
              {
                value: 'User Logged In',
                label: 'User Logged In',
              },
              {
                value: 'User Who Created Event',
                label: 'User Who Created Event',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            name="templateTaskListsId"
            control={control}
            label="Template Task List"
            required
            rules={{ required: 'Template Task List is required.' }}
            showSelected={true}
            data={
              templateTaskList?.templateTaskList.map((val) => {
                return {
                  value: val.id as string,
                  label: val.templateName as string,
                };
              }) || []
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            name="templateTaskStatus"
            control={control}
            label="Template Task Status"
            required
            rules={{ required: 'Template Task Status is required.' }}
            showSelected={true}
            data={[
              {
                value: TaskStatusEnum.Complete,
                label: TaskStatusEnum.Complete,
              },
              {
                value: TaskStatusEnum.Confirmed,
                label: TaskStatusEnum.Confirmed,
              },
              {
                value: TaskStatusEnum.InProgress,
                label: TaskStatusEnum.InProgress,
              },
              {
                value: TaskStatusEnum.Incomplete,
                label: TaskStatusEnum.Incomplete,
              },
              {
                value: TaskStatusEnum.NotStarted,
                label: TaskStatusEnum.NotStarted,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            name="timeCategory"
            label="Time Categogy"
            control={control}
            showSelected={true}
            data={[
              {
                value: 'Immediately',
                label: 'Immediately',
              },
              {
                value: 'First Week',
                label: 'First Week',
              },
              {
                value: 'Second Week',
                label: 'Second Week',
              },
              {
                value: 'Third Week',
                label: 'Third Week',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            name="dueDateType"
            control={control}
            label="Due Date Type"
            showSelected={true}
            data={[
              {
                value: 'Today',
                label: 'Today',
              },
              {
                value: 'Return Leave Date',
                label: 'Return Leave Date',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            name="dueDateLogic"
            label="Due Date Logic"
            control={control}
            type="number"
            InputProps={{ inputProps: { min: '0', step: '1' } }}
            onClick={() => {
              if (watch('dueDateLogic') === '0') {
                resetForm('');
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            name="orderIndex"
            label="Order Index"
            control={control}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            name="sendTo"
            control={control}
            label="Send To"
            showSelected={true}
            data={[
              {
                value: 'Event User ID',
                label: 'Event User ID',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            name="mailType"
            control={control}
            label="Mail Type"
            showSelected={true}
            data={[
              {
                value: MailTypeEnum.USPSFirstClass,
                label: MailTypeEnum.USPSFirstClass,
              },
              {
                value: MailTypeEnum.USPSStandard,
                label: MailTypeEnum.USPSStandard,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            name="communicationTemplate"
            control={control}
            label="Communication Template"
            showSelected={true}
            data={
              communicationTemplates?.communicationTemplates?.map((val) => {
                return {
                  value: val.id as string,
                  label: val.templateName as string,
                };
              }) || []
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            name="taskCategory"
            control={control}
            label="Task Category"
            showSelected={true}
            data={[
              {
                value: 'Immediate Arrangements',
                label: 'Immediate Arrangements',
              },
              {
                value: 'Communication',
                label: 'Communication',
              },
              {
                value: 'Emotional Support',
                label: 'Emotional Support',
              },
              {
                value: 'Will & Estate',
                label: 'Will & Estate',
              },
              {
                value: 'Memorialization',
                label: 'Memorialization',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <RichTextEditorField
            name="detail"
            label="Detail"
            control={control}
            placeholder="Type Detail"
          />
        </Grid>
      </Grid>
    </form>
  );
};
