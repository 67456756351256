import React, { useState } from 'react';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useDebounce, useIsMount } from 'store/hooks';
import { UserOverview, UsersPagination } from 'data/models/user.model';
import { getUsersPagination } from 'data/api/user.api';

const empty: UserOverview = {
  userId: '',
  rank: 0,
  companyName: '',
  lastLoginDate: '',
  createdDate: '',
  email: '',
  name: '',
  firstName: '',
  lastName: '',
};
export type TSearchBoxProps = {
  label?: string;
  required?: boolean;
  defaultValue?: UserOverview;
  setUser: (user: UserOverview) => void;
};
const renderTitle = (
  delimiter: string,
  ...params: (string | undefined)[]
): string => {
  return params.filter((str) => str && str.trim()).join(delimiter);
};
export const SearchUserNew: React.FC<TSearchBoxProps> = ({
  label,
  required,
  defaultValue,
  setUser,
}) => {
  const isMount = useIsMount();
  const [options, setOptions] = React.useState<UserOverview[]>([]);
  const [value, setValue] = React.useState<UserOverview | null | undefined>(
    defaultValue,
  );
  const [isLastPage, setIsLastPage] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [inputValue, setInputValue] = React.useState<string | undefined>(
    defaultValue
      ? renderTitle(' ', defaultValue?.firstName, defaultValue?.lastName)
      : undefined,
  );
  const debouncedValue = useDebounce(inputValue || '', 400, '');
  const [pageQuery, setPageQuery] = useState<number>(1);
  const fetch = async (pageNo: number, forced?: boolean): Promise<void> => {
    try {
      setLoading(true);
      const result: UsersPagination = await getUsersPagination(
        pageNo,
        inputValue ? inputValue : '',
      );
      setOptionsData(result.users || [], forced);
      setIsLastPage(result?.pager?.isLastPage || false);
    } catch (error: any) {
      toast.error(error.message);
      setOptionsData([], forced);
      setIsLastPage(true);
    } finally {
      setLoading(false);
    }
  };

  const setOptionsData = (users?: UserOverview[], forced?: boolean) => {
    let newOptions = options;
    newOptions = forced
      ? defaultValue
        ? (newOptions = [empty, defaultValue, ...(users || [])])
        : (newOptions = [empty, ...(users || [])])
      : _.uniqBy(newOptions.concat(users || []), (e) => e.userId);
    newOptions = _.uniqBy(newOptions, (e) => e.userId);
    setOptions(newOptions);
  };

  React.useEffect(() => {
    setIsLastPage(false);
    setPageQuery(1);
    fetch(1, true);
  }, [debouncedValue]);

  React.useEffect(() => {
    if (!isMount && !isLastPage) {
      fetch(pageQuery);
    }
  }, [pageQuery, inputValue]);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    if (
      e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
      e.currentTarget.scrollHeight
    ) {
      if (!isLastPage) {
        setPageQuery(pageQuery + 1);
      }
    }
  };

  return (
    <Autocomplete
      options={options}
      loading={loading}
      getOptionLabel={(option) => {
        return typeof option === 'string' ? option : option?.name || '';
      }}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.userId === value.userId}
      filterOptions={(x) => x}
      selectOnFocus={false}
      clearOnBlur
      handleHomeEndKeys
      value={value || null}
      noOptionsText="No Users"
      ListboxProps={{
        style: {
          maxHeight: '300px',
        },
        onScroll: handleScroll,
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(e, newValue) => {
        if (!newValue) {
          setValue(null);
          setInputValue('');
          setUser({
            userId: '',
            rank: 0,
            companyName: '',
            lastLoginDate: '',
            createdDate: '',
            email: '',
            firstName: '',
            lastName: '',
            name: '',
          });
        } else {
          if (newValue?.userId) {
            setUser(newValue);
            setValue(newValue);
          } else {
            setValue(null);
          }
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            placeholder="-- Select --"
            sx={{
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
            required={required}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.userId}>
            <Stack direction={'row'} spacing={2}>
              <Box>{option?.name}</Box>
            </Stack>
          </li>
        );
      }}
    />
  );
};
