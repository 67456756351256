import React, { useEffect, useState } from 'react';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../Routes';
import { getCompaniesPagination } from '../../../data/api/company.api';
import {
  CompaniesPagination,
  Company,
  Pagination,
} from '../../../data/models/company.model';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import SearchCompany from '../search-company/SearchCompany';
import { useDispatch } from 'react-redux';
import { saveNavigateLinkHeader } from '../../../store/navigateSlide';

export const CompaniesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [companies, setCompanies] = useState<Company[]>([]);
  const [pager, setPager] = useState<Pagination>({
    currentPage: '1',
    totalPages: 1,
    itemsPerPage: 30,
    isLastPage: true,
    itemsOnThisPage: 0,
  });
  const [pageQuery, setPageQuery] = useState<number>(1);

  useEffect(() => {
    void fetchCompaniesList();
  }, [pageQuery]);
  const fetchCompaniesList = async () => {
    const data: CompaniesPagination = await getCompaniesPagination(pageQuery);
    setCompanies(data.companies);
    setPager(data.pager);
  };

  const renderIconNext = () => {
    return { __html: '&rarr;' };
  };

  const renderIconPrevious = () => {
    return { __html: '&larr;' };
  };

  const renderClassDisablePrevious = () => {
    if (pager.currentPage === '1') {
      return 'text-[#999] cursor-not-allowed';
    }
    return '';
  };

  const renderClassDisableNext = () => {
    if (pager.isLastPage) {
      return 'text-[#999] cursor-not-allowed';
    }
    return '';
  };

  const handlePreviousPage = () => {
    if (pager.currentPage === '1') {
      return;
    }
    setPageQuery(pageQuery - 1);
  };

  const handleNextPage = () => {
    if (pager.isLastPage) {
      return;
    }
    setPageQuery(pageQuery + 1);
  };

  const handleNavigateToDetail = (fromLink: string, toLink: string) => {
    dispatch(saveNavigateLinkHeader({ fromLink, toLink }));
    navigate(toLink);
  };

  return (
    <>
      <HeaderPage
        title={{ title: 'Companies' }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_OVERVIEW,
          },
          {
            title: 'All Companies',
            selected: true,
            link: DASHBOARD_ROUTES.COMPANY_LIST,
          },
          {
            title: 'Add a Company',
            selected: false,
            link: DASHBOARD_ROUTES.NEW_COMPANY,
          },
        ]}
      />

      <div className="nv-container">
        <div className="section">
          <SearchCompany linkCurrent={DASHBOARD_ROUTES.COMPANY_LIST} />
        </div>

        <div className="section mb-10">
          <h2 className="title">All Companies</h2>
          <hr />
          <div className="table block">
            <div className="row header">
              <div className="column rank">#</div>
              <div className="column name">Name</div>
              <div className="column employees">Employees</div>
              <div className="column created">Created Date</div>
            </div>
            {companies.map((company) => {
              return (
                <a
                  onClick={() => {
                    handleNavigateToDetail(
                      DASHBOARD_ROUTES.COMPANY_LIST,
                      DASHBOARD_ROUTES.COMPANY_DETAIL + company.companyId,
                    );
                  }}
                  key={company.companyId}
                  className="row cursor-pointer"
                >
                  <div className="column rank">{company.rank}.</div>
                  <div className="column name">{company.name}</div>
                  <div className="column employees">
                    {company.employeeCount}
                  </div>
                  <div className="column created">
                    {moment(company.createdDate).format('LL')}
                  </div>
                </a>
              );
            })}
          </div>

          <div className="nv-pagination">
            <div
              className={'flex cursor-pointer ' + renderClassDisablePrevious()}
              onClick={() => handlePreviousPage()}
            >
              <div
                className="mr-2"
                dangerouslySetInnerHTML={renderIconPrevious()}
              />{' '}
              Previous
            </div>

            <div className="current">Page {pager.currentPage}</div>

            <div
              className={'flex cursor-pointer ' + renderClassDisableNext()}
              onClick={() => handleNextPage()}
            >
              Next{' '}
              <div
                className="ml-2"
                dangerouslySetInnerHTML={renderIconNext()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
