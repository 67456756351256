import LinkBehavior from 'components/LinkBehavior';
import { createTheme } from '@mui/material';
import { LinkProps } from '@mui/material/Link';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
  interface Palette {
    outline: Palette['primary'];
    onSurface: Palette['primary'];
    surface: Palette['primary'];
    grayScale: Palette['primary'];
    border: Palette['primary'];
  }

  interface PaletteOptions {
    outline?: PaletteOptions['primary'];
    onSurface?: PaletteOptions['primary'];
    surface?: PaletteOptions['primary'];
    grayScale?: PaletteOptions['primary'];
    border?: PaletteOptions['primary'];
  }
}
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#213B54',
    },
    secondary: {
      main: '#F29765',
    },
    outline: {
      main: '#79747E',
    },
    onSurface: {
      main: '#1D1B20',
    },
    surface: {
      main: '#FDFAF7',
    },
    error: {
      main: '#B3261E',
    },
    border: {
      main: '#E7E4E4',
    },
    grayScale: {
      main: '#7D818C',
    },
  },
  typography: {
    fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(','),
    body1: {
      fontSize: '15px',
      fontWeight: 500,
      color: '#213B54',
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#f5f5f5',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});
