import { CommonResponse, IListRequest } from 'data/models/common.model';
import instance from '../services/api';
import { AxiosResponse } from 'axios';
import { buildApiPath } from 'utils';
import { API_ROUTES } from 'data/ApiRoutes';
import {
  ITemplateTask,
  ITemplateTaskRoot,
} from 'data/models/template-task.model';

export const getTemplateTask = async (params: IListRequest) => {
  try {
    const data: AxiosResponse<ITemplateTaskRoot> = await instance.get(
      buildApiPath({
        path: API_ROUTES.templateTask,
        params,
      }),
    );
    return data.data;
  } catch (error: unknown) {
    if (typeof error === 'string') {
      throw Error(error.toUpperCase());
    } else if (error instanceof Error) {
      throw Error(error.message);
    }
    throw Error('');
  }
};

export const getTemplateTaskById = async (templateTaskId: string) => {
  try {
    const data: AxiosResponse<CommonResponse<ITemplateTask>> =
      await instance.get(
        buildApiPath({
          path: API_ROUTES.templateTaskById(templateTaskId),
          params: null,
        }),
      );

    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};

export const createTemplateTask = async (payload: ITemplateTask) => {
  try {
    const data: AxiosResponse<CommonResponse<ITemplateTask>> =
      await instance.post(API_ROUTES.createTemplateTask, payload);
    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};

export const updateTemplateTask = async (payload: ITemplateTask) => {
  try {
    const data: AxiosResponse<CommonResponse<ITemplateTask>> =
      await instance.post(API_ROUTES.editTemplateTask, payload);

    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};
