import React from 'react';
import { HeaderPage } from '../../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../../Routes';
import { useSelector } from 'react-redux';
import { UserInfo } from '../../../../data/models/user.model';
import { selectUser } from '../../../../store/userSlide';
import DetailFormUser from '../../components/DetailFormUser';

const UserEditNew = () => {
  const userInfo: UserInfo = useSelector(selectUser);
  return (
    <>
      <HeaderPage
        title={{
          title:
            userInfo?.firstName || userInfo?.lastName
              ? userInfo?.firstName + ' ' + userInfo?.lastName
              : '',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: true,
            link: DASHBOARD_ROUTES.USER_DETAIL_ID(userInfo.userId),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.USER_TRACKING(userInfo.userId),
          },
          {
            title: 'User Notes',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId),
          },
          {
            title: 'User Events',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId),
          },
          {
            title: 'Check-ins',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CHECK_INS(userInfo.userId),
          },
        ]}
      />
      <div className="px-[140px] pr-[8px]">
        <DetailFormUser />
      </div>
    </>
  );
};
export default UserEditNew;
