import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import Table from 'components/Table';
import { DASHBOARD_ROUTES } from 'Routes';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import { SearchBar } from 'pages/provider/components/SearchBar';
import { IListRequest } from '@data/models/common.model';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { fetchTemplateTaskAsync, setTemplateTask } from 'store/templateTask';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { htmlStringToDocument } from 'contentful-rich-text-html-parser';
import DOMPurify from 'dompurify';

const columns: GridColDef[] = [
  {
    field: 'task_name',
    minWidth: 200,
    flex: 1,
    headerName: 'Task Name',
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '13px',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.taskName}
        </Typography>
      );
    },
  },
  {
    field: 'template_task_type',
    headerName: 'Type',
    minWidth: 200,
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '13px',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.templateTaskType}
        </Typography>
      );
    },
  },
  {
    field: 'template_task_status',
    headerName: 'Status',
    sortable: true,
    flex: 1,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '13px',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.templateTaskStatus}
        </Typography>
      );
    },
  },
  {
    field: 'template_name',
    headerName: 'Communication Template',
    sortable: true,
    flex: 1,
    minWidth: 300,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '13px',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.templateName}
        </Typography>
      );
    },
  },
  {
    field: 'task_category',
    headerName: 'Task Category',
    minWidth: 200,
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '13px',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.task_category}
        </Typography>
      );
    },
  },
  // {
  //   field: 'detail',
  //   headerName: 'Detail',
  //   minWidth: 200,
  //   flex: 1,
  //   sortable: true,
  //   renderCell: (params: GridRenderCellParams) => {
  //     const contentTips = htmlStringToDocument(
  //       DOMPurify.sanitize(params.row.detail),
  //     );
  //     const plainTextTips = documentToPlainTextString(contentTips);
  //     return (
  //       <Typography
  //         sx={{
  //           fontSize: '13px',
  //           display: '-webkit-box',
  //           lineClamp: 2,
  //           overflow: 'hidden',
  //           color: '#000000',
  //           whiteSpace: 'pre-wrap',
  //           WebkitLineClamp: 2,
  //           WebkitBoxOrient: 'vertical',
  //         }}
  //       >
  //         {plainTextTips}
  //       </Typography>
  //     );
  //   },
  // },
  {
    field: 'show_find_provider_card_large',
    headerName: 'Show Find Provider',
    minWidth: 250,
    flex: 1,
    sortable: true,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      return params.row.showFindProviderCardLarge ? <CheckIcon /> : <></>;
    },
  },
];
export const tabs = [
  {
    title: 'All Template Tasks',
    selected: true,
    link: DASHBOARD_ROUTES.ROUTE_TASK,
  },
  {
    title: 'Create New',
    selected: false,
    link: DASHBOARD_ROUTES.NEW_TASK,
  },
];
const title = { title: 'Template Tasks' };

export const TaskList = () => {
  const dispatch = useDispatch();
  const status = useAppSelector((state) => state.templateTask.status);
  const templateTasks = useAppSelector((state) => state.templateTask.values);
  const [filter, setFilter] = useState<IListRequest>({
    order: 'desc',
    page: 0,
    pageSize: 50,
  });
  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    dispatch(setTemplateTask(params.row));
    window.location.href = `${DASHBOARD_ROUTES.EDIT_TASK}`.replace(
      ':templateTaskId',
      `${params.id}`,
    );
  };
  React.useEffect(() => {
    dispatch(fetchTemplateTaskAsync(filter));
  }, [JSON.stringify(filter)]);
  return (
    <>
      <HeaderPage title={title} tabs={tabs} />
      <div className="nv-container">
        <div className="section">
          <SearchBar
            placeholder="Search Template Tasks"
            debounce={300}
            onChange={(val: any) =>
              setFilter({
                ...filter,
                query: val,
              })
            }
          />
        </div>
        <Box className="section" sx={{ marginTop: '10px' }}>
          <Table
            getRowId={(r) => r.id}
            loading={status === 'loading'}
            rows={templateTasks?.templateTask || []}
            columns={columns}
            paginationMode="server"
            sortingMode="server"
            rowCount={parseInt(`${templateTasks?.pager?.total || '0'}`)}
            disableColumnFilter={true}
            disableColumnMenu={true}
            filter={filter}
            onFilterChange={setFilter}
            pageSizeOptions={[10, 25, 50, 100]}
            onRowClick={handleRowClick}
          />
        </Box>
      </div>
    </>
  );
};
