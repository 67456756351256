/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomTextField } from 'components/TextField';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';

export type TTextFieldProps = MuiTextFieldProps & {
  control?: any;
  rules?: RegisterOptions;
  name?: `${string}` | `${string}.${string}` | `${string}.${number}`;
};

const TextFieldNumber = ({
  control,
  name,
  rules = {},
  ...props
}: TTextFieldProps) => {
  if (control && name && rules) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { ref, onChange, ...restField }, fieldState }) => {
          return (
            <CustomTextField
              {...restField}
              inputRef={ref}
              error={!!fieldState.error}
              helperText={fieldState?.error?.message || ''}
              onChange={(e) => {
                onChange((e?.currentTarget?.value || '').replace(/\D/g, ''));
              }}
              {...props}
            />
          );
        }}
      />
    );
  }
  return <CustomTextField {...props} />;
};

export default TextFieldNumber;
