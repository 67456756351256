import { Backdrop, Box, CircularProgress } from '@mui/material';
import { DASHBOARD_ROUTES } from 'Routes';
import { AxiosError } from 'axios';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import { getProviderById } from 'data/api/provider.api';
import { IProviderForm } from 'data/models/provider.model';
import * as React from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatterToCaps } from 'utils';
type ContextType = { provider: IProviderForm | null };
export function useProvider() {
  return useOutletContext<ContextType>();
}
export const ProviderLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { providerId } = useParams();
  const [provider, setProvider] = React.useState<IProviderForm>();
  const [isloading, setIsloading] = React.useState<boolean>(false);
  const isCreateNew = location.pathname === DASHBOARD_ROUTES.NEW_PROVIDER;
  if (!isCreateNew) {
    if (!providerId) {
      toast.error('Invalid provider id');
      navigate(DASHBOARD_ROUTES.LIST_PROVIDER);
      return null;
    }
  }
  const fetchData = async () => {
    try {
      setIsloading(true);
      const result = await getProviderById(providerId as string);
      if (!result?.success) {
        throw new Error(result?.message);
      }
      setProvider(result?.data);
    } catch (err) {
      const error = err as Error | AxiosError;
      toast.error(
        formatterToCaps(error.message) || 'Get provider info failed!',
      );
      console.error('Error: ', error);
    } finally {
      setIsloading(false);
    }
  };

  React.useEffect(() => {
    if (!isCreateNew) {
      fetchData();
    }
  }, []);
  const title = isCreateNew
    ? { title: 'Providers' }
    : {
        title: provider ? provider?.name : '',
        subtitle: 'Provider Profile',
        hasBack: true,
        backLink: -1,
      };
  const tabs = isCreateNew
    ? [
        {
          title: 'All Providers',
          selected: false,
          link: DASHBOARD_ROUTES.ROUTE_PROVIDER,
        },
        {
          title: 'Create New',
          selected: true,
          link: DASHBOARD_ROUTES.NEW_PROVIDER,
        },
      ]
    : [
        {
          title: 'Details',
          selected: true,
          link: '#',
        },
      ];
  if (isloading) return null;
  return (
    <>
      <HeaderPage title={title} tabs={tabs} />
      <Box sx={{ marginTop: '7px' }}>
        <div className="nv-container">
          <Outlet context={{ provider }} />
        </div>
      </Box>
    </>
  );
};
