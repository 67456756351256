import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <g>
      <path
        d="M10.5 15.1667C11.7887 15.1667 12.8333 14.122 12.8333 12.8333C12.8333 11.5447 11.7887 10.5 10.5 10.5C9.21133 10.5 8.16666 11.5447 8.16666 12.8333C8.16666 14.122 9.21133 15.1667 10.5 15.1667Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3333 7H4.66667C4.02233 7 3.5 7.52233 3.5 8.16667V19.8333C3.5 20.4777 4.02234 21 4.66667 21H23.3333C23.9777 21 24.5 20.4777 24.5 19.8333V8.16667C24.5 7.52233 23.9777 7 23.3333 7Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 21C7 19.7114 8.567 18.6667 10.5 18.6667C12.433 18.6667 14 19.7114 14 21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 16.3333L16.3333 16.3333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12.8333L17.5 12.8333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgComponent;
