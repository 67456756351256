/* eslint-disable @typescript-eslint/no-empty-function */
import {
  DataGrid,
  DataGridProps,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { IListRequest } from 'data/models/common.model';
import React from 'react';
type TTableProps = DataGridProps & {
  hasBackground?: boolean;
  filter?: IListRequest;
  onFilterChange?: (params: IListRequest) => void;
};

const TableTracking = ({
  filter = { page: 0, pageSize: 50 },
  onFilterChange = () => {},
  ...props
}: TTableProps) => {
  const { page, pageSize, sortBy, order } = filter || {};
  const additionalProps =
    props.paginationMode === 'server'
      ? {
          paginationModel: { page, pageSize },
          onPaginationModelChange: (model: GridPaginationModel) => {
            onFilterChange({ ...filter, ...model });
          },
          hideFooter: (props.rowCount || 0) < 10,
          sortModel: sortBy && order ? [{ field: sortBy, sort: order }] : [],
          onSortModelChange: (model: GridSortModel) => {
            onFilterChange({
              ...filter,
              sortBy: model[0] ? model[0].field : undefined,
              order: model[0] ? model[0].sort : undefined,
            });
          },
        }
      : {
          hideFooter: props.rows.length < 10,
        };
  return (
    <div style={{ height: 'auto', width: '100%' }}>
      <DataGrid
        disableColumnFilter={true}
        disableRowSelectionOnClick={true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
              page: 0,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        getRowHeight={() => {
          return 'auto';
        }}
        {...additionalProps}
        {...props}
        sx={{
          width: '100%',
          borderColor: 'transparent',
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-row--lastVisible': {
            '& .MuiDataGrid-cell': {
              borderBottom: '1px solid #DBD8D8',
            },
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: ' #fcfdff',
          },
          '& .MuiDataGrid-withBorderColor': {
            borderColor: (theme) => theme.palette.border.main,
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#F8F9FB',
            borderTop: '1px solid #DBD8D8',
            maxHeight: '47px !important',
            minHeight: '47px !important',
          },
          '& .MuiDataGrid-columnHeader': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: '#213B54',
            fontWeight: 700,
            fontSize: '13px',
            lineHeight: '20px',
          },
          '& .MuiDataGrid-cell': {
            color: '#213B54',
            fontWeight: 500,
            fontSize: '12px',
            cursor: 'pointer',
            minHeight: '42px !important',
          },
          '& .MuiTablePagination-root': {
            color: (theme) => theme.palette.primary.main,
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight:
              props.rowCount === 0 && props.rows.length === 0
                ? '100px'
                : 'auto',
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            minHeight: !props.rows?.length ? '100px !important' : '100%',
          },
          ...props.sx,
        }}
      />
    </div>
  );
};

export default TableTracking;
