import React from 'react';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../Routes';
import { useSelector } from 'react-redux';
import { selectCompany } from '../../../store/companySlide';
import { Link, useParams } from 'react-router-dom';
import { CompanyInfo } from '../../../data/models/company.model';
import DetailFormUser from 'pages/user/components/DetailFormUser';
import { Stack } from '@mui/material';

export const CompanyUserAddNew = () => {
  const { company_id } = useParams();
  const companyInfo: CompanyInfo = useSelector(selectCompany);

  return (
    <>
      <HeaderPage
        title={{
          title: companyInfo?.name ? companyInfo?.name : '',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_DETAIL + company_id,
          },
          {
            title: 'Company Notes',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_NOTES(company_id),
          },
          {
            title: 'Users',
            selected: true,
            link: DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id),
          },
        ]}
      />

      <div className="nv-container">
        <div className="section employees">
          <div className="tabs bg-[#FDFAF7] w-[360px]">
            <Stack
              direction={'row'}
              justifyContent={'space-around'}
              height={'49px'}
              alignItems={'center'}
            >
              <Link
                to={DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id)}
                className="tab"
                style={{
                  color: '#213B54',
                  fontSize: '13px',
                  fontWeight: 700,
                  textDecoration: 'none',
                }}
              >
                Users
              </Link>
              <Link
                to={DASHBOARD_ROUTES.COMPANY_EMPLOYEES_ADD(company_id)}
                className="tab"
                style={{
                  color: '#213B54',
                  height: '100%',
                  alignItems: 'center',
                  borderBottom: '3px solid #213B54',
                  textAlign: 'center',
                  display: 'flex',
                  fontSize: '13px',
                  textDecoration: 'none',
                  fontWeight: 700,
                }}
              >
                Add a User
              </Link>
              <Link
                to={DASHBOARD_ROUTES.COMPANY_EMPLOYEES_IMPORT(company_id)}
                className="tab "
                style={{
                  color: '#213B54',
                  fontSize: '13px',
                  fontWeight: 700,
                  textDecoration: 'none',
                }}
              >
                Import Users
              </Link>
            </Stack>
          </div>
          <hr />

          <div className="filter no-padding" />
          <DetailFormUser />
        </div>
      </div>
    </>
  );
};
