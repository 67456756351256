import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511 511" {...props}>
    <g fill="currentColor">
      <path d="M503.5 436H399.795c7.13-9.007 9.992-20.574 7.837-31.891L386.38 292.532a39.538 39.538 0 0 0-26.312-30.082l-54.196-18.065a7.465 7.465 0 0 0-2.872-.366V229.62c10.341-11.62 16-26.396 16-42.12v-17.734c9.29-3.138 16-11.93 16-22.266v-40c0-26.191-21.309-47.5-47.5-47.5h-64c-10.584 0-19.557 7.033-22.489 16.672C186.744 79.671 176 92.354 176 107.5v40c0 10.335 6.71 19.127 16 22.266v15.387a66.997 66.997 0 0 0 16 43.459v15.407a7.468 7.468 0 0 0-2.872.367l-54.196 18.065a39.538 39.538 0 0 0-26.312 30.082L103.368 404.11c-2.156 11.317.707 22.884 7.837 31.891H7.5a7.5 7.5 0 0 0 0 15h496a7.5 7.5 0 0 0 0-15.001zm-288-177a7.5 7.5 0 0 0 7.5-7.5v-9.919c9.01 5.649 19.437 9.056 30.552 9.39.659.02 1.315.029 1.971.029 11.614 0 22.742-3.101 32.477-8.927v9.427a7.5 7.5 0 0 0 7.5 7.5c.105 0 .207-.011.311-.016C293.858 277.481 276.527 292 255.5 292s-38.358-14.519-40.311-33.016c.104.005.206.016.311.016zM191 147.5v-40c0-9.098 7.402-16.5 16.5-16.5a7.5 7.5 0 0 0 7.5-7.5c0-4.687 3.813-8.5 8.5-8.5h64c17.92 0 32.5 14.579 32.5 32.5v40c0 1.442-.364 2.8-1 3.992V139.5c0-12.958-10.542-23.5-23.5-23.5h-80c-12.958 0-23.5 10.542-23.5 23.5v11.992a8.455 8.455 0 0 1-1-3.992zm16-8c0-4.687 3.813-8.5 8.5-8.5h80c4.687 0 8.5 3.813 8.5 8.5v48c0 13.23-5.23 25.593-14.727 34.809-9.493 9.212-22.035 14.065-35.271 13.669C228.085 235.2 207 212.399 207 185.152V139.5zM159.5 436a.5.5 0 0 1-.5-.5v-104a.5.5 0 0 1 .5-.5h192a.5.5 0 0 1 .5.5v104a.5.5 0 0 1-.5.5h-192zm208 0h-.525c.005-.167.025-.331.025-.5v-104c0-8.547-6.953-15.5-15.5-15.5h-192c-8.547 0-15.5 6.953-15.5 15.5v104c0 .169.02.333.025.5h-1.855a24.423 24.423 0 0 1-18.875-8.881 24.42 24.42 0 0 1-5.191-20.203l21.252-111.577a24.523 24.523 0 0 1 16.32-18.658l44.75-14.917C203.768 287.216 227.183 307 255.5 307s51.732-19.784 55.075-45.236l44.75 14.916a24.524 24.524 0 0 1 16.32 18.659l21.252 111.577a24.422 24.422 0 0 1-5.191 20.203A24.424 24.424 0 0 1 368.831 436H367.5z" />
      <path d="M255.5 364c-12.958 0-23.5 10.542-23.5 23.5s10.542 23.5 23.5 23.5 23.5-10.542 23.5-23.5-10.542-23.5-23.5-23.5zm0 32c-4.687 0-8.5-3.813-8.5-8.5s3.813-8.5 8.5-8.5 8.5 3.813 8.5 8.5-3.813 8.5-8.5 8.5z" />
    </g>
  </svg>
);

export default SvgComponent;
