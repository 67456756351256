/* eslint-disable @typescript-eslint/no-explicit-any */
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import React, { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

type TCheckboxSingleProps = CheckboxProps & {
  label?: ReactNode;
  control?: any;
  rules?: { [key: string]: string };
  name?: `${string}` | `${string}.${string}` | `${string}.${number}`;
};

const CheckboxSingle = ({
  control,
  name,
  rules = {},
  label = '',
  ...props
}: TCheckboxSingleProps) => {
  if (control && name && rules) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, ...restField }, fieldState }) => {
          const isChecked =
            restField.value !== undefined
              ? restField.value
              : props.checked || false;
          return (
            <FormControl
              required
              error={!!fieldState.error}
              component="fieldset"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    {...restField}
                    {...props}
                    checked={isChecked}
                    onChange={onChange}
                  />
                }
                label={label}
              />
              {fieldState.error && (
                <FormHelperText>{fieldState.error.message}</FormHelperText>
              )}
            </FormControl>
          );
        }}
      />
    );
  }
  return <FormControlLabel control={<Checkbox {...props} />} label={label} />;
};

export default CheckboxSingle;
