import React from 'react';
import TextField, { TTextFieldProps } from 'components/TextField';

type TTextareaProps = TTextFieldProps;

const Textarea = ({ ...props }: TTextareaProps) => {
  return <TextField multiline={true} inputProps={{}} {...props} />;
};

export default Textarea;
