import React from 'react';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../Routes';
import { useParams } from 'react-router-dom';
import { CompanyInfo } from '../../../data/models/company.model';
import { useSelector } from 'react-redux';
import { selectCompany } from '../../../store/companySlide';
import { DetailFormCompany } from '../components/DetailFormCompany';

const CompanyDetailNew = () => {
  const { company_id } = useParams();
  const companyInfo: CompanyInfo = useSelector(selectCompany);

  return (
    <>
      <HeaderPage
        title={{
          title: companyInfo?.name ? companyInfo?.name : '',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: true,
            link: DASHBOARD_ROUTES.COMPANY_DETAIL + company_id,
          },
          {
            title: 'Company Notes',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_NOTES(company_id),
          },
          {
            title: 'Users',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_TRACKING(company_id),
          },
        ]}
      />

      <div className="nv-container">
        <DetailFormCompany />
      </div>
    </>
  );
};

export default CompanyDetailNew;
