import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { DASHBOARD_ROUTES } from '../../Routes';
import IconBuilding from '../../assets/icon-components/building';
import IconContact from '../../assets/icon-components/contact';
import IconUser from '../../assets/icon-components/user';
import logoCompact from '../../assets/temp/logo_compact.svg';
import './Sidebar.scss';

export const Sidebar: React.FC = () => {
  const activateClassLink = ({ isActive }: { isActive: boolean }) => {
    if (isActive) {
      return 'text-[#f6b26b]';
    } else {
      return 'text-white';
    }
  };

  return (
    <div
      id="menu"
      className="fixed left-0 top-0 w-[64px] h-full bg-[#2c3f54] text-center pt-[10px] box-border"
    >
      <Link to={DASHBOARD_ROUTES.ROUTE_COMPANY}>
        <img width="24px" src={logoCompact} alt="logoCompact" />
      </Link>

      <div className="pt-[60px]">
        <div className="mb-[40px]">
          <NavLink
            to={DASHBOARD_ROUTES.ROUTE_COMPANY}
            className={activateClassLink}
          >
            <IconBuilding className="cursor-pointer w-[24px]" />
          </NavLink>
        </div>
        <div className="mb-[40px]">
          <NavLink
            to={DASHBOARD_ROUTES.ROUTE_USER}
            className={activateClassLink}
          >
            <IconUser className="cursor-pointer w-[24px]" />
          </NavLink>
        </div>

        <div className="mb-[40px]">
          <NavLink
            to={DASHBOARD_ROUTES.ROUTE_PROVIDER}
            className={activateClassLink}
          >
            <IconContact className="cursor-pointer w-[24px]" />
          </NavLink>
        </div>
      </div>
    </div>
  );
};
