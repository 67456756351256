import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { UserInfo } from '../data/models/user.model';

export interface UserState {
  value: UserInfo;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: UserState = {
  value: {
    userId: '',
    companyId: '',
    email: '',
    personalEmail: '',
    isAdmin: false,
    state: '',
    city: '',
    photoUrl: '',
    firstName: '',
    lastName: '',
    hasAcceptedTerms: false,
    hasBenefitsPage: false,
    permissions: {
      admin: false,
      care: false,
      estate: false,
      grief: false,
      events: false,
    },
    companyName: '',
    lastLoginDate: '',
    createdDate: '',
    totalLogins: 0,
    isEmployee: false,
    isActive: false,
    activationLink: '',
  },
  status: 'idle',
};

export const userSlide = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state, action: PayloadAction<UserInfo>) => {
      state.value = action.payload;
    },
    clearUser: (state) => {
      state.value = {
        userId: '',
        companyId: '',
        email: '',
        personalEmail: '',
        isAdmin: false,
        state: '',
        city: '',
        photoUrl: '',
        firstName: '',
        lastName: '',
        hasAcceptedTerms: false,
        hasBenefitsPage: false,
        permissions: {
          admin: false,
          care: false,
          estate: false,
          grief: false,
          events: false,
        },
        companyName: '',
        lastLoginDate: '',
        createdDate: '',
        totalLogins: 0,
        isEmployee: false,
        isActive: false,
        activationLink: '',
      };
    },
  },
});

export const { saveUser, clearUser } = userSlide.actions;
export const selectUser = (state: RootState) => state.user.value;
export default userSlide.reducer;
