import React, { useEffect, useState } from 'react';
import { HeaderPage } from '../../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../../Routes';
import { Link, useParams } from 'react-router-dom';
import { UserInfo } from '../../../../data/models/user.model';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../store/userSlide';
import moment from 'moment';
import { NoteInfo } from '../../../../data/models/user-note.model';
import { getUserNotes } from '../../../../data/api/user-note.api';

const UserNotes = () => {
  const { user_id } = useParams();
  const userInfo: UserInfo = useSelector(selectUser);
  const [userNotesInfo, setUserNotesInfo] = useState<NoteInfo[]>([]);
  const [includeAuto, setIncludeAuto] = useState<boolean>(true);

  useEffect(() => {
    if (user_id) {
      void fetchUserNotes(user_id);
    }
  }, [includeAuto]);

  const fetchUserNotes = async (userId: string) => {
    await getUserNotes(userId, includeAuto)
      .then((resp) => {
        setUserNotesInfo(resp.notes);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderCheckIcon = () => {
    return { __html: '&check;' };
  };
  return (
    <>
      <HeaderPage
        title={{
          title:
            userInfo?.firstName || userInfo?.lastName
              ? userInfo?.firstName + ' ' + userInfo?.lastName
              : '',
          subtitle: 'User Profile',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.USER_DETAIL_ID(userInfo.userId),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.USER_TRACKING(userInfo.userId),
          },
          {
            title: 'User Notes',
            selected: true,
            link: DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId),
          },
          {
            title: 'User Events',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId),
          },
          {
            title: 'Check-ins',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CHECK_INS(userInfo.userId),
          },
        ]}
      />

      <div className="nv-container">
        <div className="section notes mb-10">
          <div className="tabs">
            <Link
              to={DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId)}
              className="tab selected"
            >
              Internal Notes
            </Link>
            <Link
              to={DASHBOARD_ROUTES.USER_ID_NOTES_ADD(userInfo.userId)}
              className="tab"
            >
              Add Note
            </Link>
          </div>
          <hr />

          <div className="filter">
            <input
              id="checkbox-include-notes"
              type="checkbox"
              defaultChecked={includeAuto}
              onChange={() => setIncludeAuto(!includeAuto)}
            />
            <label htmlFor="checkbox-include-notes" className="cursor-pointer">
              Include notes that were created automatically
            </label>
          </div>

          <div className="table">
            <div className="row header">
              <div className="column auto">Auto</div>
              <div className="column note">Note</div>
              <div className="column user">Created by</div>
              <div className="column created-note">Date</div>
            </div>

            {userNotesInfo.map((companyNote, index) => {
              return (
                <div className="row" key={index}>
                  {companyNote.isAutoGenerated ? (
                    <div
                      className="column auto"
                      dangerouslySetInnerHTML={renderCheckIcon()}
                    />
                  ) : (
                    <div className="column auto">&nbsp;</div>
                  )}
                  <div className="column note">{companyNote.note}</div>
                  <div className="column user">
                    {companyNote.createdByUsername}
                  </div>
                  <div className="column created-note">
                    {moment(companyNote.createdDate).format('LL')}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserNotes;
