import React, { useState } from 'react';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../Routes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CompanyInfo } from '../../../data/models/company.model';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { createCompanyNote } from '../../../data/api/company-note.api';
import { useSelector } from 'react-redux';
import { selectCompany } from '../../../store/companySlide';

const schema = yup
  .object({
    note: yup.string().required('Note is required.'),
  })
  .required();

export const CompanyNoteAdd = () => {
  const { company_id } = useParams();
  const companyInfo: CompanyInfo = useSelector(selectCompany);

  const [isSubmitting, setIsSubmitting] = useState(true);
  const { register, formState, getValues } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { isValid, errors } = formState;
  const navigate = useNavigate();

  const handleCreateCompanyNote = async () => {
    if (isSubmitting && company_id) {
      setIsSubmitting(false);

      const { note } = getValues();
      await createCompanyNote(company_id, { note })
        .then(() => {
          toast.success('Create company note successfully!');
          navigate(DASHBOARD_ROUTES.COMPANY_ID_NOTES(company_id));
        })
        .catch((error) => {
          console.error(error);
          toast.error('Create company note fail!');
        })
        .finally(() => {
          setIsSubmitting(true);
        });
    }
  };

  return (
    <>
      <HeaderPage
        title={{
          title: companyInfo?.name ? companyInfo?.name : '',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_DETAIL + company_id,
          },
          {
            title: 'Company Notes',
            selected: true,
            link: DASHBOARD_ROUTES.COMPANY_ID_NOTES(company_id),
          },
          {
            title: 'Users',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id),
          },
        ]}
      />

      <div className="nv-container">
        <div className="section notes">
          <div className="tabs">
            <Link
              to={DASHBOARD_ROUTES.COMPANY_ID_NOTES(company_id)}
              className="tab"
            >
              Internal Notes
            </Link>
            <Link
              to={DASHBOARD_ROUTES.COMPANY_ID_NOTES_NEW(company_id)}
              className="tab selected"
            >
              Add Note
            </Link>
          </div>
          <hr />

          <div className="filter">
            <label>Leave a new internal note.</label>
          </div>

          <div id="add-note">
            <div className="mb-4">
              <textarea className="resize-y" {...register('note')} />
              <span className="text-red-100 text-sm pt-2">
                {errors.note?.message}
              </span>
            </div>

            <div className="buttons">
              <input
                disabled={!isValid}
                className={!isValid ? 'opacity-50 cursor-not-allowed' : ''}
                type="submit"
                value="Add Note"
                onClick={() => handleCreateCompanyNote()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
