import React, { useState } from 'react';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useDebounce, useIsMount } from 'store/hooks';
import { CompanyOverview } from 'data/models/company.model';
import { getCompaniesPagination } from 'data/api/company.api';
const empty: CompanyOverview = {
  companyId: '',
  rank: 0,
  name: '',
  employeeCount: 0,
  createdDate: '',
};
export type TSearchBoxProps = {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: CompanyOverview;
  setCompany: (company: CompanyOverview) => void;
};
const renderTitle = (
  delimiter: string,
  ...params: (string | undefined)[]
): string => {
  return params.filter((str) => str && str.trim()).join(delimiter);
};
export const SearchBox: React.FC<TSearchBoxProps> = ({
  label,
  required,
  defaultValue,
  disabled,
  setCompany,
}) => {
  const isMount = useIsMount();
  const [options, setOptions] = React.useState<CompanyOverview[]>([]);
  const [value, setValue] = React.useState<CompanyOverview | null | undefined>(
    defaultValue,
  );
  const [isLastPage, setIsLastPage] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [inputValue, setInputValue] = React.useState<string | undefined>(
    defaultValue ? renderTitle(' ', defaultValue?.name) : undefined,
  );
  const debouncedValue = useDebounce(inputValue || '', 400, '');
  const [pageQuery, setPageQuery] = useState<number>(1);

  const fetch = async (pageNo: number, forced?: boolean): Promise<void> => {
    try {
      setLoading(true);
      const result = await getCompaniesPagination(
        pageNo,
        inputValue ? inputValue : '',
      );
      setOptionsData(result.companies || [], forced);
      setIsLastPage(result?.pager?.isLastPage || false);
    } catch (error: any) {
      toast.error(error.message);
      setOptionsData([], forced);
      setIsLastPage(true);
    } finally {
      setLoading(false);
    }
  };

  const setOptionsData = (companys?: CompanyOverview[], forced?: boolean) => {
    let newOptions = options;
    newOptions = forced
      ? defaultValue
        ? (newOptions = [empty, defaultValue, ...(companys || [])])
        : (newOptions = [empty, ...(companys || [])])
      : _.uniqBy(newOptions.concat(companys || []), (us) => us.companyId);
    newOptions = _.uniqBy(newOptions, (us) => us.companyId);
    setOptions(newOptions);
  };

  React.useEffect(() => {
    setIsLastPage(false);
    setPageQuery(1);
    fetch(1, true);
  }, [debouncedValue]);

  React.useEffect(() => {
    if (!isMount && !isLastPage) {
      fetch(pageQuery);
    }
  }, [pageQuery]);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    if (
      e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
      e.currentTarget.scrollHeight
    ) {
      if (!isLastPage) {
        setPageQuery(pageQuery + 1);
      }
    }
  };

  return (
    <Autocomplete
      options={options}
      loading={loading}
      getOptionLabel={(option) => {
        return typeof option === 'string' ? option : option?.name;
      }}
      autoHighlight
      isOptionEqualToValue={(option, value) =>
        option.companyId === value.companyId
      }
      filterOptions={(x) => x}
      selectOnFocus={false}
      clearOnBlur
      handleHomeEndKeys
      disabled={disabled}
      value={value || null}
      noOptionsText="No company"
      ListboxProps={{
        style: {
          maxHeight: '300px',
        },
        onScroll: handleScroll,
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(e, newValue) => {
        if (!newValue) {
          setValue(null);
          setInputValue('');
          setCompany({
            companyId: '',
            rank: 0,
            name: '',
            employeeCount: 0,
            createdDate: '',
          });
        } else {
          if (newValue?.companyId) {
            setCompany(newValue);
            setValue(newValue);
          } else {
            setValue(null);
          }
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            placeholder="-- Select --"
            sx={{
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
            required={required}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.companyId}>
            <Stack direction={'row'} spacing={2}>
              <Box>{option?.name}</Box>
            </Stack>
          </li>
        );
      }}
    />
  );
};
