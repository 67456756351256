/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { Controller } from 'react-hook-form';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { CustomTextField } from '../TextField';

// eslint-disable-next-line react/display-name
const TextFieldWithMask = React.forwardRef<
  HTMLInputElement,
  PatternFormatProps<any>
>(
  (
    {
      format = '###-##-####',
      mask = '_',
      control,
      name,
      rules = {},
      ...restProps
    },
    ref,
  ) => {
    if (control && name && rules) {
      return (
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field, fieldState }) => {
            return (
              <PatternFormat
                {...field}
                getInputRef={ref}
                customInput={CustomTextField}
                format={format}
                mask={mask}
                error={!!fieldState.error}
                helperText={fieldState?.error?.message || ''}
                {...restProps}
              />
            );
          }}
        />
      );
    }

    return (
      <PatternFormat
        getInputRef={ref}
        customInput={CustomTextField}
        format={format}
        mask={mask}
        {...restProps}
      />
    );
  },
);

export default TextFieldWithMask;
