export const ACCOUNT_ROUTES = {
  LOGIN: '/login',
};

export const DASHBOARD_ROUTES = {
  HOME: '/',

  ROUTE_TASK_LIST: '/template-task-list',
  LIST_TASK_LIST: '/template-task-list/list',
  NEW_TASK_LIST: '/template-task-list/new',
  DETAIL_TASK_LIST: '/template-task-list/detail',
  EDIT_TASK_LIST: '/template-task-list/edit/:templateTaskListId',

  ROUTE_TASK: '/template-task',
  LIST_TASK: '/template-task/list',
  NEW_TASK: '/template-task/new',
  DETAIL_TASK: '/template-task/detail',
  EDIT_TASK: '/template-task/edit/:templateTaskId',

  ROUTE_PROVIDER: '/provider',
  LIST_PROVIDER: '/provider/list',
  NEW_PROVIDER: '/provider/new',
  DETAIL_PROVIDER: '/provider/detail',
  EDIT_PROVIDER: '/provider/edit/:providerId',

  ROUTE_COMPANY: '/company',
  COMPANY_OVERVIEW: '/company/overview',
  COMPANY_LIST: '/company/list',
  NEW_COMPANY: '/company/new',
  COMPANY_DETAIL: '/company/detail/',
  COMPANY_DETAIL_ID: '/company/detail/:company_id',

  ROUTE_COMPANY_NOTES: '/company/:company_id/notes',
  COMPANY_ID_NOTES: (company_id: string | undefined) =>
    `/company/${company_id}/notes`,
  ROUTE_COMPANY_NOTES_ADD: '/company/:company_id/notes/new',
  COMPANY_ID_NOTES_NEW: (company_id: string | undefined) =>
    `/company/${company_id}/notes/new`,
  ROUTE_COMPANY_TRACKING: '/company/:company_id/tracking',
  COMPANY_ID_TRACKING: (company_id: string | undefined) =>
    `/company/${company_id}/tracking`,

  ROUTE_COMPANY_EMPLOYEES_ID: '/company/:company_id/employees',
  COMPANY_ID_EMPLOYEES: (company_id: string | undefined) =>
    `/company/${company_id}/employees`,
  ROUTE_COMPANY_EMPLOYEES_ADD: '/company/:company_id/employees/new',
  COMPANY_EMPLOYEES_ADD: (company_id: string | undefined) =>
    `/company/${company_id}/employees/new`,
  ROUTE_COMPANY_EMPLOYEES_IMPORT: '/company/:company_id/employees/import',
  COMPANY_EMPLOYEES_IMPORT: (company_id: string | undefined) =>
    `/company/${company_id}/employees/import`,

  ROUTE_USER: '/user',
  ROUTE_USER_OVERVIEW: '/user/overview',
  ROUTE_USER_LIST: '/user/list',

  ROUTE_USER_ADD: '/user/new',
  ROUTE_USER_DETAIL: '/user/detail',
  ROUTE_USER_DETAIL_ID: '/user/detail/:user_id',
  USER_DETAIL_ID: (user_id: string | undefined) => `/user/detail/${user_id}`,

  ROUTE_USER_NOTES: '/user/:user_id/notes',
  USER_ID_NOTES: (user_id: string | undefined) => `/user/${user_id}/notes`,
  ROUTE_USER_NOTES_ADD: '/user/:user_id/notes/new',
  USER_ID_NOTES_ADD: (user_id: string | undefined) =>
    `/user/${user_id}/notes/new`,

  ROUTE_USER_LOSS_EVENTS: '/user/user-events',
  ROUTE_USER_ID_LOSS_EVENTS: '/user/:user_id/user-events',
  USER_ID_LOSS_EVENTS: (user_id: string | undefined) =>
    `/user/${user_id}/user-events`,
  ROUTE_USER_ID_LOSS_EVENT_ID: '/user/:user_id/user-events/:loss_event_id',
  USER_ID_LOSS_EVENT_ID: (
    user_id: string | undefined,
    loss_event_id: string | undefined,
  ) => `/user/${user_id}/user-events/${loss_event_id}`,

  ROUTE_USER_TRACKING: '/user/detail/:user_id/tracking',
  USER_TRACKING: (user_id: string | undefined) =>
    `/user/detail/${user_id}/tracking`,

  ROUTE_USER_ID_CHECK_INS: '/user/:user_id/check-ins',
  USER_ID_CHECK_INS: (user_id: string | undefined) =>
    `/user/${user_id}/check-ins`,

  DASHBOARD: '/dashboard',
};
