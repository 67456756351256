import { getTemplateTaskList } from 'data/api/template-task-list.api';
import {
  ITemplateTaskListForm,
  ITemplateTaskListRoot,
} from '@data/models/template-task-list.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IListRequest } from '@data/models/common.model';
import { RootState } from './store';
export interface TemplateTaskListState {
  value?: ITemplateTaskListForm;
  status: 'idle' | 'loading' | 'failed';
  values?: ITemplateTaskListRoot;
}
const initialState: TemplateTaskListState = {
  value: undefined,
  status: 'idle',
  values: undefined,
};

export const fetchTemplateTaskListAsync = createAsyncThunk(
  'admin/fetchTemplateTaskListStatus',
  async (params: IListRequest) => {
    const response = await getTemplateTaskList(params);
    return response;
  },
);

export const templateTaskListSlice = createSlice({
  name: 'templateTaskList',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchTemplateTaskListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTemplateTaskListAsync.fulfilled, (state, action) => {
        state.values = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchTemplateTaskListAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
  reducers: {
    setTemplateTaskList: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { setTemplateTaskList } = templateTaskListSlice.actions;
export const selectTemplateTaskLists = (state: RootState) =>
  state.templateTaskList.values;
export const selectTemplateTaskList = (state: RootState) =>
  state.templateTaskList.value;
export default templateTaskListSlice.reducer;
