import React, { useEffect, useState } from 'react';
import { HeaderPage } from '../../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../../Routes';
import { useParams } from 'react-router-dom';
import { UserInfo } from '../../../../data/models/user.model';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../store/userSlide';
import { EventInstance } from 'data/models/loss-event.model';
import { getUserEventsInstancesForUser } from 'data/api/event.api';
import moment from 'moment';
import { Stack, Typography, styled } from '@mui/material';
import Button from 'components/Button';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(360deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(270deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const UserLossEvents = () => {
  const userInfo: UserInfo = useSelector(selectUser);
  const [eventInstances, setEventInstances] = useState<EventInstance[]>();
  const { user_id } = useParams();

  useEffect(() => {
    if (user_id) {
      void fetchEventInstances(user_id);
    }
  }, [user_id]);

  const fetchEventInstances = async (userId: string) => {
    await getUserEventsInstancesForUser(userId)
      .then((instances) => {
        setEventInstances(instances?.userEvents || []);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([0]);

  const accordionClicked = (index: number) => {
    if (expandedAccordions.includes(index))
      setExpandedAccordions(
        expandedAccordions.filter((number) => number !== index),
      );
    else setExpandedAccordions([...expandedAccordions, index]);
  };

  const expandAll = () => {
    if (expandedAccordions?.length === eventInstances?.length) {
      setExpandedAccordions([]);
    } else {
      const newArray: number[] = [];
      eventInstances?.forEach((ev, index) => {
        newArray.push(index);
      });
      setExpandedAccordions(newArray);
    }
  };

  return (
    <>
      <HeaderPage
        title={{
          title:
            userInfo?.firstName || userInfo?.lastName
              ? userInfo?.firstName + ' ' + userInfo?.lastName
              : '',
          subtitle: 'User Profile',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.USER_DETAIL_ID(userInfo.userId),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.USER_TRACKING(userInfo.userId),
          },
          {
            title: 'User Notes',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId),
          },
          {
            title: 'User Events',
            selected: true,
            link: DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId),
          },
          {
            title: 'Check-ins',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CHECK_INS(userInfo.userId),
          },
        ]}
      />
      <Stack paddingLeft={'140px'}>
        <Button
          variant="outlined"
          disabled={eventInstances && eventInstances.length > 0 ? false : true}
          sx={{
            backgroundColor: '#ffffff',
            color: '#1D1B20',
            fontWeight: 700,
            height: '32px',
            marginTop: '24px',
            width:
              expandedAccordions?.length === eventInstances?.length
                ? '113px'
                : '105px',
            fontSize: '14px',
            borderRadius: '8px !important',
            border: '1px solid #213B54',
            marginBottom: '16px',
            minWidth: '105px',
          }}
          onClick={expandAll}
        >
          {expandedAccordions?.length === eventInstances?.length
            ? 'Collapse All'
            : 'Expand All'}
        </Button>
        {eventInstances &&
          eventInstances.length > 0 &&
          eventInstances.map((event: EventInstance, index: number) => {
            return (
              <>
                <Accordion
                  key={index}
                  onChange={() => accordionClicked(index)}
                  expanded={expandedAccordions.includes(index)}
                  style={{
                    backgroundColor: '#fff',
                    background: '#fff',
                  }}
                  sx={{
                    '& .MuiButtonBase-root': {
                      backgroundColor: '#fff',
                    },
                    '& .MuiCollapse-root': {
                      backgroundColor: '#FBFCFD',
                      border: 'none',
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                      color: '#213B54',
                    },
                    borderBottom: expandedAccordions.includes(index)
                      ? ''
                      : '1px solid #DBD8D8 !important',
                    border: 'none',
                    marginRight: '82px',
                    '& .MuiCollapse-wrapper': {
                      borderTop: '1px solid #DBD8D8',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Stack
                      direction={{ xs: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      width={'100%'}
                    >
                      <Typography
                        sx={{
                          fontSize: '15px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '24px',
                          letterSpacing: '0.15px',
                        }}
                      >
                        {event.relatedRoleName
                          ? event.relatedRoleRelationship
                            ? event.eventName?.charAt(0)?.toUpperCase() +
                              event.eventName?.substr(1)?.toLowerCase() +
                              '-' +
                              event.relatedRoleName +
                              `(${event.relatedRoleRelationship})`
                            : event.eventName?.charAt(0)?.toUpperCase() +
                              event.eventName?.substr(1)?.toLowerCase() +
                              '-' +
                              event.relatedRoleName +
                              ''
                          : event.eventName?.charAt(0)?.toUpperCase() +
                            event.eventName?.substr(1)?.toLowerCase()}
                      </Typography>
                      <Stack direction={'row'} spacing={1}>
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: 'normal',
                          }}
                        >
                          Last Modified:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: 'normal',
                          }}
                        >
                          {event.lastUpdateDate
                            ? moment(event.lastUpdateDate).format('M/DD/YYYY')
                            : ''}
                        </Typography>
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      marginLeft: '34px',
                      border: 'none',
                    }}
                  >
                    <Stack spacing={'6px'}>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            minWidth: '170px',
                            lineHeight: 'normal',
                          }}
                        >
                          Event:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.eventName?.charAt(0)?.toUpperCase() +
                            event.eventName?.substr(1)?.toLowerCase() || ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: 'normal',
                            minWidth: '170px',
                          }}
                        >
                          Relationship to User:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.relatedRoleRelationship || ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: 'normal',
                            minWidth: '170px',
                          }}
                        >
                          Name of Impacted:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.relatedRoleName || ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: 'normal',
                            minWidth: '170px',
                          }}
                        >
                          Care Focus:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.careFocus?.replaceAll(',', ', ') || ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            minWidth: '170px',
                            lineHeight: 'normal',
                          }}
                        >
                          User Notes:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.otherNote || ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            minWidth: '170px',
                            lineHeight: 'normal',
                          }}
                        >
                          Internal Point of Contact:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.internalPointOfContactName || ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            minWidth: '170px',
                            lineHeight: 'normal',
                          }}
                        >
                          Internal Notes:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.internalNotes || ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            minWidth: '170px',
                            lineHeight: 'normal',
                          }}
                        >
                          Religious Affiliations:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.religiousAffiliations || ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: 'normal',
                            minWidth: '170px',
                          }}
                        >
                          Unexpected Event:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.unexpectedEvent || ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            minWidth: '170px',
                            lineHeight: 'normal',
                          }}
                        >
                          Event Timeframe:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event?.deathDateRange
                            ? event?.deathDateRange?.charAt(0)?.toUpperCase() +
                              event?.deathDateRange?.substr(1)?.toLowerCase()
                            : ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: 'normal',
                            minWidth: '170px',
                          }}
                        >
                          Death Date:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.deathDate
                            ? moment
                                .utc(event.deathDate)
                                .local()
                                .format('MM/DD/YYYY')
                            : ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            minWidth: '170px',
                            lineHeight: 'normal',
                          }}
                        >
                          Death State:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.deathState || ' '}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            minWidth: '170px',
                            lineHeight: 'normal',
                          }}
                        >
                          Last Modified Date:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.lastUpdateDate
                            ? moment(event.lastUpdateDate).format('M/DD/YYYY')
                            : ''}
                          {event.lastUpdateDate
                            ? ', ' + event.modifyByUserName
                            : ''}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={2}
                      >
                        <Typography
                          sx={{
                            color: '#7D818C',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            minWidth: '170px',
                            lineHeight: 'normal',
                          }}
                        >
                          Created Date:
                        </Typography>
                        <Typography
                          sx={{
                            color: '#213B54',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                          }}
                        >
                          {event.createdDate
                            ? moment(event.createdDate).format('M/DD/YYYY')
                            : ''}
                          {', ' + event.createByUserName}
                        </Typography>
                      </Stack>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
        {!eventInstances ||
          (eventInstances.length <= 0 && (
            <Stack
              width={'100%'}
              height={'500px'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Typography>No user events have been created yet.</Typography>
            </Stack>
          ))}
      </Stack>
    </>
  );
};
export default UserLossEvents;
