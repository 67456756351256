import React, { useState } from 'react';
import { SearchBar } from '../components/SearchBar';
import { useAppSelector } from 'store/hooks';
import { fetchProvidersAsync, setProvider } from 'store/providerSlide';
import { IListRequest } from 'data/models/common.model';
import Box from '@mui/material/Box';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Table from 'components/Table';
import { DASHBOARD_ROUTES } from 'Routes';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          fontWeight={'bold'}
          fontSize={'15px'}
          lineHeight={'24px'}
          sx={{
            color: '#F29765',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: 'provider_type',
    headerName: 'Provider Type',
    flex: 1,
    sortable: true,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.providerType || ''}`,
  },
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    sortable: true,
  },
  {
    field: 'last_modified_date',
    headerName: 'Last Modified',
    flex: 1,
    sortable: true,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.lastModifiedDate || ''}`,
    renderCell: (params: GridRenderCellParams) => {
      return params.row.lastModifiedDate
        ? moment.utc(params.row.lastModifiedDate).local().format('MM/DD/YYYY')
        : '';
    },
  },
  {
    field: 'can_prescribe',
    headerName: 'Active',
    width: 150,
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.canPrescribe || ''}`,
    renderCell: (params: GridRenderCellParams) => {
      if (params.value) {
        return <CheckBoxIcon sx={{ color: 'primary.main' }} />;
      }
      return <CheckBoxOutlineBlankIcon sx={{ color: 'primary.main' }} />;
    },
  },
];
const tabs = [
  {
    title: 'All Providers',
    selected: true,
    link: DASHBOARD_ROUTES.ROUTE_PROVIDER,
  },
  {
    title: 'Create New',
    selected: false,
    link: DASHBOARD_ROUTES.NEW_PROVIDER,
  },
];
const title = { title: 'Providers' };

export const ProviderList = () => {
  const dispatch = useDispatch();
  const status = useAppSelector((state) => state.provider.status);
  const providers = useAppSelector((state) => state.provider.values);
  const [filter, setFilter] = useState<IListRequest>({
    sortBy: 'last_modified_date',
    order: 'desc',
    page: 0,
    pageSize: 50,
  });
  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    dispatch(setProvider(params.row));
    window.location.href = `${DASHBOARD_ROUTES.EDIT_PROVIDER}`.replace(
      ':providerId',
      `${params.id}`,
    );
  };
  React.useEffect(() => {
    dispatch(fetchProvidersAsync(filter));
  }, [JSON.stringify(filter)]);
  return (
    <>
      <HeaderPage title={title} tabs={tabs} />
      <div className="nv-container">
        <div className="section">
          <SearchBar
            placeholder="Search providers"
            onChange={(val) =>
              setFilter({
                ...filter,
                query: val,
              })
            }
            debounce={300}
          />
        </div>
        <Box className="section" sx={{ marginTop: '10px' }}>
          <Table
            getRowId={(r) => r.providerId}
            loading={status === 'loading'}
            rows={providers?.providers || []}
            columns={columns}
            paginationMode="server"
            sortingMode="server"
            rowCount={parseInt(`${providers?.pager?.total || '0'}`)}
            disableColumnFilter={true}
            disableColumnMenu={true}
            filter={filter}
            onFilterChange={setFilter}
            pageSizeOptions={[10, 25, 50, 100]}
            onRowClick={handleRowClick}
          />
        </Box>
      </div>
    </>
  );
};
