import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IListRequest } from 'data/models/common.model';
import { RootState } from './store';
import {
  ITemplateTask,
  ITemplateTaskRoot,
} from 'data/models/template-task.model';
import { getTemplateTask } from 'data/api/template-task.api';
export interface TemplateTaskState {
  value?: ITemplateTask;
  status: 'idle' | 'loading' | 'failed';
  values?: ITemplateTaskRoot;
}
const initialState: TemplateTaskState = {
  value: undefined,
  status: 'idle',
  values: undefined,
};

export const fetchTemplateTaskAsync = createAsyncThunk(
  'admin/fetchTemplateTaskStatus',
  async (params: IListRequest) => {
    const response = await getTemplateTask(params);
    return response;
  },
);

export const templateTaskSlice = createSlice({
  name: 'templateTask',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchTemplateTaskAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTemplateTaskAsync.fulfilled, (state, action) => {
        state.values = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchTemplateTaskAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
  reducers: {
    setTemplateTask: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { setTemplateTask } = templateTaskSlice.actions;
export const selectTemplateTasks = (state: RootState) =>
  state.templateTask.values;
export const selectTemplateTask = (state: RootState) =>
  state.templateTask.value;
export default templateTaskSlice.reducer;
