/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { Controller } from 'react-hook-form';
import moment from 'moment';

type TDatePickerProps = MuiDatePickerProps<any> & {
  control?: any;
  rules?: { [key: string]: string };
  name?: `${string}` | `${string}.${string}` | `${string}.${number}`;
  error?: boolean;
  helperText?: ReactNode;
  required?: boolean;
  fullWidth?: boolean;
};

const CustomDatePicker = ({
  error,
  helperText,
  required,
  fullWidth,
  ...props
}: TDatePickerProps) => {
  return (
    <MuiDatePicker
      slotProps={{
        textField: {
          fullWidth,
          required: required,
          InputLabelProps: {
            shrink: true,
            className: `!font-medium !pr-1.5 ${
              props.disabled ? 'text-navy' : '!text-navy bg-white'
            }`,
            sx: {
              '&.Mui-error': {
                color: '#B3261E !important',
              },
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            },
          },
          InputProps: {
            className: '!text-onSurface !font-medium !text-sm',
          },
          inputProps: { className: '!py-3 md:!py-4 !leading-6 !h-auto' },
          sx: {
            '& .MuiOutlinedInput-notchedOutline': !props.disabled
              ? {
                  borderColor: '#79747E !important',
                }
              : {},
            '& .Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: '#B3261E !important',
            },
            input: {
              background: 'none',
              ': hover': {
                cursor: props.disabled ? 'not-allowed' : '',
              },
            },
          },
          error,
          helperText,
        },
      }}
      {...props}
    />
  );
};

const DatePicker = ({
  control,
  name,
  rules = {},
  ...props
}: TDatePickerProps) => {
  if (control && name && rules) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { ref, value, ...restField }, fieldState }) => {
          const convertedValue = !value ? null : moment(value || moment());
          return (
            <CustomDatePicker
              {...restField}
              inputRef={ref}
              error={!!fieldState.error}
              helperText={fieldState?.error?.message || ''}
              {...props}
              value={convertedValue}
            />
          );
        }}
      />
    );
  }
  return <CustomDatePicker {...props} />;
};

export default DatePicker;
