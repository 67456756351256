import React, { useEffect, useState } from 'react';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../Routes';
import { getCompaniesOverview } from '../../../data/api/company.api';
import {
  CompaniesOverviewModel,
  CompanyOverview,
  Statistics,
} from '../../../data/models/company.model';
import moment from 'moment';
import SearchCompany from '../search-company/SearchCompany';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveNavigateLinkHeader } from 'store/navigateSlide';

export const CompaniesOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [largestCompanies, setLargestCompanies] = useState<CompanyOverview[]>(
    [],
  );
  const [newestCompanies, setNewestCompanies] = useState<CompanyOverview[]>([]);
  const [statistics, setStatistics] = useState<Statistics>();

  useEffect(() => {
    void fetchCompaniesOverview();
  }, []);
  const fetchCompaniesOverview = async () => {
    const data: CompaniesOverviewModel = await getCompaniesOverview();
    setLargestCompanies(data.largestCompanies);
    setNewestCompanies(data.newestCompanies);
    setStatistics(data.statistics);
  };

  const renderItemCompany = (company: CompanyOverview) => {
    return (
      <a
        onClick={() => {
          handleNavigateToDetail(
            DASHBOARD_ROUTES.COMPANY_OVERVIEW,
            DASHBOARD_ROUTES.COMPANY_DETAIL + company.companyId,
          );
        }}
        key={company.rank}
        className="row cursor-pointer"
      >
        <div className="column rank">{company.rank}.</div>
        <div className="column name">{company.name}</div>
        <div className="column employees">{company.employeeCount}</div>
        <div className="column created">
          {moment(company.createdDate).format('LL')}
        </div>
      </a>
    );
  };

  const handleNavigateToDetail = (fromLink: string, toLink: string) => {
    dispatch(saveNavigateLinkHeader({ fromLink, toLink }));
    navigate(toLink);
  };

  return (
    <>
      <HeaderPage
        title={{ title: 'Companies' }}
        tabs={[
          {
            title: 'Overview',
            selected: true,
            link: DASHBOARD_ROUTES.COMPANY_OVERVIEW,
          },
          {
            title: 'All Companies',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_LIST,
          },
          {
            title: 'Add a Company',
            selected: false,
            link: DASHBOARD_ROUTES.NEW_COMPANY,
          },
        ]}
      />

      <div className="nv-container">
        <div className="section">
          <SearchCompany linkCurrent={DASHBOARD_ROUTES.COMPANY_OVERVIEW} />
        </div>

        <div className="section">
          <h2 className="title">Largest Companies</h2>
          <hr />
          <div className="table block">
            <div className="row header">
              <div className="column rank">#</div>
              <div className="column name">Name</div>
              <div className="column employees">Employees</div>
              <div className="column created">Created Date</div>
            </div>
            {largestCompanies.map((company) => {
              return renderItemCompany(company);
            })}
          </div>
        </div>

        <div className="section">
          <h2 className="title">Newest Companies</h2>
          <hr />
          <div className="table">
            <div className="row header">
              <div className="column rank">#</div>
              <div className="column name">Name</div>
              <div className="column employees">Employees</div>
              <div className="column created">Created Date</div>
            </div>
            {newestCompanies.map((company) => {
              return renderItemCompany(company);
            })}
          </div>
        </div>

        <div className="section stats mb-6">
          <h2 className="title">Statistics</h2>
          <hr />
          <div className="table">
            <div className="row">
              <div className="column header stat">Total active companies</div>
              <div className="column stat-value">{statistics?.totalActive}</div>
            </div>
            <div className="row">
              <div className="column header stat">Average company size</div>
              <div className="column stat-value">
                {statistics?.averageSize} employees
              </div>
            </div>
            <div className="row">
              <div className="column header stat">Total employees</div>
              <div className="column stat-value">
                {statistics?.totalEmployees} employees
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
