import * as React from 'react';
import _ from 'lodash';
export type SearcBarTypes = {
  onChange?: (value: string) => void;
  placeholder?: string;
  debounce?: number;
};
export const SearchBar: React.FC<SearcBarTypes> = (props) => {
  const searchValue = _.debounce(
    (value) => (props.onChange ? props.onChange(value) : value),
    props.debounce || 0,
  );
  return (
    <div id="search">
      <input
        type="textbox"
        placeholder={props.placeholder || ''}
        onChange={(e) => searchValue(e.target.value)}
        autoComplete="off"
      />
      <div className="icon" />
    </div>
  );
};
