import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import Table from 'components/Table';
import { DASHBOARD_ROUTES } from 'Routes';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import { SearchBar } from 'pages/provider/components/SearchBar';
import { IListRequest } from '@data/models/common.model';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import {
  fetchTemplateTaskListAsync,
  setTemplateTaskList,
} from 'store/templateTaskListSlide';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const columns: GridColDef[] = [
  {
    field: 'template_name',
    headerName: 'Name',
    flex: 1,
    sortable: true,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '13px',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.templateName}
        </Typography>
      );
    },
  },
  {
    field: 'audience',
    headerName: 'Audience',
    flex: 1,
    sortable: true,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '13px',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.audience}
        </Typography>
      );
    },
  },
  {
    field: 'related_role_relationship',
    headerName: 'Relationship',
    flex: 1,
    sortable: true,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '13px',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.relatedRoleRelationship}
        </Typography>
      );
    },
  },
  {
    field: 'event_type',
    headerName: 'Event Type',
    flex: 1,
    sortable: true,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '13px',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.eventName}
        </Typography>
      );
    },
  },
  {
    field: 'death_date_range',
    headerName: 'Death Date',
    sortable: true,
    align: 'center',
    flex: 1,
    headerAlign: 'center',
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '13px',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.deathDateRange}
        </Typography>
      );
    },
  },
  {
    field: 'navigation_role',
    headerName: 'Navigation Role',
    sortable: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    minWidth: 200,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          sx={{
            fontSize: '13px',
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.row.navigationRole}
        </Typography>
      );
    },
  },
  {
    field: 'include_due_dates',
    headerName: 'Include Due Dates',
    sortable: true,
    flex: 1,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      return params.row.includeDueDates ? (
        <CheckIcon
          sx={{
            color: '#213B54',
          }}
        />
      ) : (
        <></>
      );
    },
    minWidth: 200,
  },
];
export const tabs = [
  {
    title: 'All Template Task List',
    selected: true,
    link: DASHBOARD_ROUTES.ROUTE_TASK_LIST,
  },
  {
    title: 'Create New',
    selected: false,
    link: DASHBOARD_ROUTES.NEW_TASK_LIST,
  },
];
const title = { title: 'Template Task List' };

export const TaskListList = () => {
  const dispatch = useDispatch();
  const status = useAppSelector((state) => state.templateTaskList.status);
  const templateTaskList = useAppSelector(
    (state) => state.templateTaskList.values,
  );
  const [filter, setFilter] = useState<IListRequest>({
    sortBy: undefined,
    order: undefined,
    page: 0,
    pageSize: 50,
  });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    dispatch(setTemplateTaskList(params.row));
    window.location.href = `${DASHBOARD_ROUTES.EDIT_TASK_LIST}`.replace(
      ':templateTaskListId',
      `${params.id}`,
    );
  };

  React.useEffect(() => {
    dispatch(fetchTemplateTaskListAsync(filter));
  }, [JSON.stringify(filter)]);

  return (
    <>
      <HeaderPage title={title} tabs={tabs} />
      <div className="nv-container">
        <div className="section">
          <SearchBar
            placeholder="Search Template Task List"
            onChange={(val: any) =>
              setFilter({
                ...filter,
                query: val,
              })
            }
            debounce={300}
          />
        </div>
        <Box className="section" sx={{ marginTop: '10px' }}>
          <Table
            getRowId={(r) => r.id}
            loading={status === 'loading'}
            rows={templateTaskList?.templateTaskList || []}
            columns={columns}
            paginationMode="server"
            sortingMode="server"
            rowCount={parseInt(`${templateTaskList?.pager?.total || '0'}`)}
            disableColumnFilter={true}
            disableColumnMenu={true}
            filter={filter}
            onFilterChange={setFilter}
            pageSizeOptions={[10, 20, 50, 100]}
            onRowClick={handleRowClick}
          />
        </Box>
      </div>
    </>
  );
};
