import React from 'react';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../Routes';
import { DetailFormCompany } from '../components/DetailFormCompany';

export const NewCompanyUI = () => {
  return (
    <>
      <HeaderPage
        title={{ title: 'Companies' }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_OVERVIEW,
          },
          {
            title: 'All Companies',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_LIST,
          },
          {
            title: 'Add a Company',
            selected: true,
            link: DASHBOARD_ROUTES.NEW_COMPANY,
          },
        ]}
      />
      <div className="nv-container">
        <DetailFormCompany />
      </div>
    </>
  );
};
