import React, { useEffect } from 'react';
import googleImage from 'assets/temp/google.png';
import { DASHBOARD_ROUTES } from '../../Routes';
import { useLocation, useNavigate } from 'react-router-dom';
import './Login.scss';
import {
  fetchRefreshToken,
  loginWithGoogle,
  setSessionStorageToken,
} from '../../data/api/auth.api';
import queryString from 'query-string';
import { TokenInfo } from '../../data/models/auth.model';
import { toast } from 'react-toastify';

const GOOGLE_SSO = 'sso';
export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { token } = queryParams;

  useEffect(() => {
    const handleCheckAccount = async () => {
      const data: TokenInfo | undefined = await fetchRefreshToken();
      if (data?.access_token) {
        navigate(DASHBOARD_ROUTES.COMPANY_OVERVIEW);
      }
    };

    if (queryParams.error === GOOGLE_SSO) {
      toast.error('You are not authorized to access this page');
    }

    if (token) {
      setSessionStorageToken({
        refresh_token: token as string,
        access_token: '',
      });

      void handleCheckAccount();
    }
  }, [location, navigate]);

  const handleLoginWithGoogle = async () => {
    const { authorizationUrl } = await loginWithGoogle();
    if (authorizationUrl) {
      window.location.href = authorizationUrl;
    }
  };

  return (
    <div
      id="login"
      className="bg-[#2c3f54] w-[600px] p-[50px] text-white text-center"
    >
      <div className="title bg-no-repeat text-[28px] leading-[38px] font-normal ml-[50px] mb-[40px]">
        betterleave <span className="text-orange-100">admin</span>
      </div>
      <div className="text-lg flex justify-center">
        <div onClick={() => handleLoginWithGoogle()}>
          <img
            src={googleImage}
            className="cursor-pointer"
            width="191"
            alt="googleImage"
          />
        </div>
      </div>
    </div>
  );
};
