import React, { ChangeEvent, useState } from 'react';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../Routes';
import { useDispatch, useSelector } from 'react-redux';
import { saveCompany, selectCompany } from '../../../store/companySlide';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CompanyInfo } from '../../../data/models/company.model';
import { toast } from 'react-toastify';
import { uploadEmployeesCSV } from '../../../data/api/company-employee.api';
import { getCompanyDetail } from '../../../data/api/company.api';
import { Box, Stack, Typography } from '@mui/material';

export const CompanyImportUser = () => {
  const { company_id } = useParams();
  const companyInfo: CompanyInfo = useSelector(selectCompany);
  const [fileCSV, setFileCsv] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUploadEmployeesCSV = async (event: ChangeEvent) => {
    const files = (event.target as HTMLInputElement).files;
    if (isSubmitting && files?.length && company_id) {
      setIsSubmitting(false);
      const fileCurrent = files[0];

      const ACCEPT_TYPES = ['.csv', 'text/csv'];
      if (!ACCEPT_TYPES.includes(fileCurrent.type)) {
        toast.error(
          'This file type is not supported. The following file types are supported: .csv!',
        );
        return;
      }

      await uploadEmployeesCSV(company_id, fileCurrent)
        .then((resp) => {
          if (!resp.success) {
            toast.error(resp.message);
            return;
          }
          toast.success('Upload CSV success!');
          if (company_id) {
            void fetchCompanyDetail(company_id);
            navigate(DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id));
          }
        })
        .catch((error) => {
          if (!error.success) {
            toast.error(error.message);
            return;
          }
          toast.error('Upload CSV fail!');
          console.error(error);
        })
        .finally(() => {
          setFileCsv('');
          setIsSubmitting(true);
        });
    }
  };

  const fetchCompanyDetail = async (companyId: string) => {
    await getCompanyDetail(companyId)
      .then((resp) => {
        dispatch(saveCompany(resp.company));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <HeaderPage
        title={{
          title: companyInfo?.name ? companyInfo?.name : '',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_DETAIL + company_id,
          },
          {
            title: 'Company Notes',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_NOTES(company_id),
          },
          {
            title: 'Users',
            selected: true,
            link: DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id),
          },
        ]}
      />

      <div className="nv-container">
        <div className="section employees">
          <div className="tabs bg-[#FDFAF7] w-[360px]">
            <Stack
              direction={'row'}
              justifyContent={'space-around'}
              height={'49px'}
              alignItems={'center'}
            >
              <Link
                to={DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id)}
                className="tab"
                style={{
                  color: '#213B54',
                  fontSize: '13px',
                  fontWeight: 700,
                  textDecoration: 'none',
                }}
              >
                Users
              </Link>
              <Link
                to={DASHBOARD_ROUTES.COMPANY_EMPLOYEES_ADD(company_id)}
                className="tab"
                style={{
                  color: '#213B54',
                  fontSize: '13px',
                  fontWeight: 700,
                  textDecoration: 'none',
                }}
              >
                Add a User
              </Link>
              <Link
                to={DASHBOARD_ROUTES.COMPANY_EMPLOYEES_IMPORT(company_id)}
                className="tab "
                style={{
                  color: '#213B54',
                  height: '100%',
                  alignItems: 'center',
                  borderBottom: '3px solid #213B54',
                  textAlign: 'center',
                  display: 'flex',
                  fontSize: '13px',
                  textDecoration: 'none',
                  fontWeight: 700,
                }}
              >
                Import Users
              </Link>
            </Stack>
          </div>
          <hr />

          <div className="filter no-padding" />
        </div>
      </div>

      <div className="nv-container">
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              color: 'black',
              fontWeight: 700,
              marginY: '24px',
            }}
          >
            CSV Import
          </Typography>
          <Typography
            sx={{
              color: '#adadad',
              marginBottom: '8px',
            }}
          >
            Bulk import using CSV
          </Typography>
          <Typography
            sx={{
              color: 'black',
              marginBottom: '8px',
            }}
          >
            The uploaded CSV file must contain a row of headers signifying{' '}
            <strong>first name</strong>, <strong>last name</strong>, and{' '}
            <strong>primary email</strong>.
          </Typography>
          <Typography
            sx={{
              color: 'black',
            }}
          >
            The columns for <strong>state</strong> and <strong>address</strong>{' '}
            are optional.
          </Typography>
          <div className="mt-[30px]">
            <label
              className="px-[20px] rounded-[100px] py-[10px] text-[#fff] bg-[#213B54] font-semibold border-0 cursor-pointer"
              htmlFor="upload-file-csv"
            >
              Choose file
              <input
                id="upload-file-csv"
                name="upload-file-csv"
                type="file"
                accept=".csv, text/csv"
                value={fileCSV}
                hidden
                onChange={handleUploadEmployeesCSV}
              />
            </label>
          </div>
        </Box>
      </div>
    </>
  );
};
