import { AxiosResponse } from 'axios';
import { API_ROUTES } from '../ApiRoutes';
import instance from '../services/api';
import { buildApiPath } from 'utils';
import { IUsersTrackingRoot } from 'data/models/user-tracking.model';
import { GridSortDirection } from '@mui/x-data-grid';

interface IListRequest {
  query?: string;
  page: number;
  pageSize: number;
  sortBy?: string;
  order?: GridSortDirection;

  contentType?: string[];
  startDate?: string;
  endDate?: string;
  title?: string;
}

export const getUserTracking = async (
  params: IListRequest,
  userId?: string,
) => {
  try {
    const data: AxiosResponse<IUsersTrackingRoot> = await instance.get(
      buildApiPath({
        path: API_ROUTES.adminUserTracking(userId || ''),
        params: params || null,
      }),
    );
    return data.data;
  } catch (error: unknown) {
    if (typeof error === 'string') {
      throw Error(error.toUpperCase());
    } else if (error instanceof Error) {
      throw Error(error.message);
    }
    throw Error('');
  }
};
