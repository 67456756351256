import React, { useEffect, useState } from 'react';
import { HeaderPage } from '../../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../../Routes';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { saveNavigateLinkHeader } from '../../../../store/navigateSlide';
import {
  StatisticsUserOverview,
  UserOverview,
  UsersOverviewModel,
} from '../../../../data/models/user.model';
import { getUsersOverview } from '../../../../data/api/user.api';
import SearchUser from '../../components/search-user/SearchUser';

const UsersOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [recentlyActiveUsers, setRecentlyActiveUsers] = useState<
    UserOverview[]
  >([]);
  const [newestUsers, setNewestUsers] = useState<UserOverview[]>([]);
  const [statistics, setStatistics] = useState<StatisticsUserOverview>();

  useEffect(() => {
    void fetchCompaniesOverview();
  }, []);
  const fetchCompaniesOverview = async () => {
    const data: UsersOverviewModel = await getUsersOverview();
    setRecentlyActiveUsers(data.recentlyActiveUsers);
    setNewestUsers(data.newestUsers);
    setStatistics(data.statistics);
  };

  const renderItemUser = (user: UserOverview) => {
    return (
      <a
        onClick={() => {
          handleNavigateToDetail(
            DASHBOARD_ROUTES.ROUTE_USER_OVERVIEW,
            DASHBOARD_ROUTES.USER_DETAIL_ID(user.userId),
          );
        }}
        key={user.rank}
        className="row cursor-pointer"
      >
        <div className="column rank">{user.rank}.</div>
        <div className="column username">{user.name}</div>
        <div className="column employees">
          {user.companyName ? user.companyName : 'n/a'}
        </div>
        <div className="column last-login">
          {user.lastLoginDate ? (
            moment(user.lastLoginDate).format('LL')
          ) : (
            <div>n/a</div>
          )}
        </div>
        <div className="column user-created">
          {user.createdDate ? (
            moment(user.createdDate).format('LL')
          ) : (
            <div>n/a</div>
          )}
        </div>
      </a>
    );
  };

  const handleNavigateToDetail = (fromLink: string, toLink: string) => {
    dispatch(saveNavigateLinkHeader({ fromLink, toLink }));
    navigate(toLink);
  };

  return (
    <>
      <HeaderPage
        title={{ title: 'Users' }}
        tabs={[
          {
            title: 'Overview',
            selected: true,
            link: DASHBOARD_ROUTES.ROUTE_USER_OVERVIEW,
          },
          {
            title: 'All Users',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_LIST,
          },
          {
            title: 'Add a User',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_ADD,
          },
          {
            title: 'User Events',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_LOSS_EVENTS,
          },
        ]}
      />

      <div className="nv-container">
        <div className="section">
          <SearchUser linkCurrent={DASHBOARD_ROUTES.ROUTE_USER_OVERVIEW} />
        </div>

        <div className="section">
          <h2 className="title">Recently Active Users</h2>
          <hr />
          <div className="table">
            <div className="row header">
              <div className="column rank">#</div>
              <div className="column username">Name</div>
              <div className="column employees">Company</div>
              <div className="column last-login">Last Login Date</div>
              <div className="column user-created">Created Date</div>
            </div>
            {recentlyActiveUsers.map((user) => {
              return renderItemUser(user);
            })}
          </div>
        </div>

        <div className="section">
          <h2 className="title">Newest Users</h2>
          <hr />
          <div className="table">
            <div className="row header">
              <div className="column rank">#</div>
              <div className="column username">Name</div>
              <div className="column employees">Company</div>
              <div className="column last-login">Last Login Date</div>
              <div className="column user-created">Created Date</div>
            </div>
            {newestUsers.map((user) => {
              return renderItemUser(user);
            })}
          </div>
        </div>

        <div className="section stats mb-6">
          <h2 className="title">Statistics</h2>
          <hr />
          <div className="table">
            <div className="row">
              <div className="column header stat">Total users</div>
              <div className="column stat-value">{statistics?.totalUsers}</div>
            </div>
            <div className="row">
              <div className="column header stat">Total user events</div>
              <div className="column stat-value">
                {statistics?.totalLossEvents}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UsersOverview;
