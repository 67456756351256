/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import React from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';

export type TTextFieldProps = MuiTextFieldProps & {
  control?: any;
  rules?: RegisterOptions;
  name?: `${string}` | `${string}.${string}` | `${string}.${number}`;
};

export const CustomTextField = (props: TTextFieldProps) => {
  return (
    <MuiTextField
      fullWidth
      placeholder={
        props.placeholder ||
        (props.label ? `Type ${props.label}` : 'Type here...')
      }
      InputLabelProps={{
        shrink: true,
        className: `!pr-1.5 ${props.disabled ? '' : 'bg-white'}`,
        sx: {
          '&.Mui-error': {
            color: '#B3261E !important',
          },
        },
      }}
      SelectProps={{
        className: `${!props.value ? '!text-onSurface/40' : '!text-onSurface'}`,
      }}
      InputProps={{
        className: `${props.select ? '' : '!text-onSurface'}`,
        sx: {
          '&.Mui-error': {
            color: '#B3261E !important',
          },
        },
      }}
      inputProps={{
        ...props.inputProps,
        className: `!py-3 md:!py-4 !leading-6 !h-auto  ${
          props.type === 'email' ? ' lowercase' : ''
        }`,
        sx: {
          '::placeholder':
            props.type === 'email' ? { textTransform: 'none' } : {},
        },
      }}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: props.disabled
            ? '#EFEEF0 !important'
            : '#79747E !important',
        },
        '& .Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B3261E !important',
        },
        '& .MuiFormLabel-asterisk': {
          color: 'red',
        },
      }}
      {...props}
    />
  );
};

const TextField = ({
  control,
  name,
  rules = {},
  ...props
}: TTextFieldProps) => {
  if (control && name && rules) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={props.defaultValue || ''}
        render={({ field: { ref, ...restField }, fieldState }) => {
          return (
            <CustomTextField
              {...restField}
              inputRef={ref}
              error={!!fieldState.error}
              helperText={fieldState?.error?.message || ''}
              {...props}
            />
          );
        }}
      />
    );
  }
  return <CustomTextField {...props} />;
};

export default TextField;
