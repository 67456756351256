import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import instance from '../services/api';
import { CommonResponse, IListRequest } from 'data/models/common.model';
import {
  IProvider,
  IProviderForm,
  IProviderListRoot,
} from 'data/models/provider.model';
import { API_ROUTES } from 'data/ApiRoutes';
import { buildApiPath } from 'utils';
import { SignedPutUrl } from '@data/models/signed-put-rl.model';
export const getProviders = async (params: IListRequest) => {
  try {
    const data: AxiosResponse<IProviderListRoot> = await instance.get(
      buildApiPath({
        path: API_ROUTES.providers,
        params,
      }),
    );

    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};
export const getProviderById = async (providerId: string) => {
  try {
    const data: AxiosResponse<CommonResponse<IProvider>> = await instance.get(
      buildApiPath({
        path: API_ROUTES.providerById(providerId),
        params: null,
      }),
    );

    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};

export const createProvider = async (payload: IProviderForm) => {
  try {
    const data: AxiosResponse<CommonResponse<IProvider>> = await instance.post(
      API_ROUTES.createProvider,
      payload,
    );

    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};
export const updateProvider = async (payload: IProviderForm) => {
  try {
    const data: AxiosResponse<CommonResponse<IProvider>> = await instance.post(
      API_ROUTES.editProvider,
      payload,
    );

    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};

export const getSignedPutUrl = async (
  providerId: string,
  contentType: string,
): Promise<SignedPutUrl> => {
  const data: AxiosResponse<SignedPutUrl> = await instance
    .get(
      API_ROUTES.adminProvider(providerId) +
        `/photo?contentType=${contentType}`,
    )
    .catch((error) => {
      throw error;
    });
  return data.data;
};

export const uploadLogoProviderToAWS = async (
  signedPutUrl: string,
  file: File,
) => {
  const config: AxiosRequestConfig = {
    method: 'put',
    url: signedPutUrl,
    headers: {
      'Content-Type': file.type,
    },
    data: file,
  };
  const data = await axios(config);
  return data.data;
};
