import { AxiosResponse } from 'axios';
import { API_ROUTES } from '../ApiRoutes';
import instance from '../services/api';
import {
  CompanyNotes,
  NoteCreate,
  NoteInfo,
} from '../models/company-note.model';

export const getCompanyNotes = async (
  companyId: string,
  includeAuto: boolean,
): Promise<CompanyNotes> => {
  const data: AxiosResponse<CompanyNotes> = await instance
    .get(API_ROUTES.companiesNotes(companyId) + `?includeAuto=${includeAuto}`)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const createCompanyNote = async (
  companyId: string,
  payload: NoteCreate,
): Promise<NoteInfo> => {
  const data: AxiosResponse<NoteInfo> = await instance
    .post(API_ROUTES.companiesNotes(companyId), payload)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const getCompanyNoteDetail = async (
  companyId: string,
  companyNoteId: string,
): Promise<NoteInfo> => {
  const data: AxiosResponse<{ note: NoteInfo }> = await instance
    .get(API_ROUTES.companiesNotesByNoteId(companyId, companyNoteId))
    .catch((error) => {
      throw error;
    });

  return data.data.note;
};

export const updateCompanyNote = async (
  companyId: string,
  companyNoteId: string,
  payload: NoteInfo,
): Promise<NoteInfo> => {
  const data: AxiosResponse<NoteInfo> = await instance
    .patch(API_ROUTES.companiesNotesByNoteId(companyId, companyNoteId), payload)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const deleteCompanyNote = async (
  companyId: string,
  companyNoteId: string,
): Promise<{
  success: boolean;
  message: string;
}> => {
  const data: AxiosResponse<{
    success: boolean;
    message: string;
  }> = await instance
    .delete(API_ROUTES.companiesNotesByNoteId(companyId, companyNoteId))
    .catch((error) => {
      throw error;
    });

  return data.data;
};
