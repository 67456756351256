import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTES } from '../../../Routes';
import { getCompaniesSearch } from '../../../data/api/company.api';
import { saveNavigateLinkHeader } from '../../../store/navigateSlide';
import { useDispatch } from 'react-redux';
import { ClickAwayListener } from '@mui/material';

const SearchCompany = ({ linkCurrent }: { linkCurrent: string }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [companiesSearch, setCompaniesSearch] = useState<
    { companyId: string; name: string }[]
  >([]);
  const [notFoundSearch, setNotFoundSearch] = useState<boolean>(false);
  const [inputValueSearch, setInputValueSearch] = useState<string>();

  const handleSearchCompany = async (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInputValueSearch(inputValue);
    if (inputValue) {
      const response = await getCompaniesSearch(event.target.value);
      if (response.length === 0) {
        setNotFoundSearch(true);
      } else {
        setNotFoundSearch(false);
      }
      setCompaniesSearch(response);
    } else {
      setCompaniesSearch([]);
    }
  };

  const handleNavigateToDetail = (fromLink: string, toLink: string) => {
    dispatch(saveNavigateLinkHeader({ fromLink, toLink }));
    navigate(toLink);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setInputValueSearch('');
      }}
    >
      <div id="search">
        <input
          type="textbox"
          placeholder="Search companies"
          onChange={handleSearchCompany}
        />
        <div className="icon" />
        {inputValueSearch?.length === 0 ? (
          <></>
        ) : companiesSearch.length === 0 && notFoundSearch ? (
          <div className="absolute z-10 w-full overflow-y-scroll max-h-[300px] bg-white drop-shadow-lg px-4 py-4 border border-solid border-[#e9ebef] text-[#333]">
            Not found
          </div>
        ) : (
          <div className="absolute z-10 w-full bg-white drop-shadow-lg">
            {companiesSearch.map((company) => {
              return (
                <a
                  onClick={() => {
                    handleNavigateToDetail(
                      linkCurrent,
                      DASHBOARD_ROUTES.COMPANY_DETAIL + company.companyId,
                    );
                  }}
                  key={company.companyId}
                  className="no-underline cursor-pointer"
                >
                  <div className="py-2 px-10 border border-solid border-[#e9ebef] text-[#333]">
                    {company.name}
                  </div>
                </a>
              );
            })}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
export default SearchCompany;
