import React, { useEffect, useState } from 'react';
import { HeaderPage } from '../../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../../Routes';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveNavigateLinkHeader } from '../../../../store/navigateSlide';
import {
  AllLossEventsPagination,
  EventInstance,
  Pagination,
} from '../../../../data/models/loss-event.model';
import { getAllLossEventsPagination } from '../../../../data/api/event.api';
import moment from 'moment';

const LossEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [allLossEvents, setAllLossEvents] = useState<EventInstance[]>([]);
  const [pager, setPager] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 30,
    isLastPage: true,
    itemsOnThisPage: 0,
  });
  const [pageQuery, setPageQuery] = useState<number>(1);

  useEffect(() => {
    void fetchUsersList();
  }, [pageQuery]);
  const fetchUsersList = async () => {
    const data: AllLossEventsPagination = await getAllLossEventsPagination(
      pageQuery,
    );
    setAllLossEvents(data.eventInstances);
    setPager(data.pager);
  };

  const renderIconNext = () => {
    return { __html: '&rarr;' };
  };

  const renderIconPrevious = () => {
    return { __html: '&larr;' };
  };

  const renderClassDisablePrevious = () => {
    if (pager.currentPage === 1) {
      return 'text-[#999] cursor-not-allowed';
    }
    return '';
  };

  const renderClassDisableNext = () => {
    if (pager.isLastPage) {
      return 'text-[#999] cursor-not-allowed';
    }
    return '';
  };

  const handlePreviousPage = () => {
    if (pager.currentPage === 1) {
      return;
    }
    setPageQuery(pageQuery - 1);
  };

  const handleNextPage = () => {
    if (pager.isLastPage) {
      return;
    }
    setPageQuery(pageQuery + 1);
  };

  const handleNavigateToDetail = (fromLink: string, toLink: string) => {
    dispatch(saveNavigateLinkHeader({ fromLink, toLink }));
    navigate(toLink);
  };

  return (
    <>
      <HeaderPage
        title={{ title: 'Users' }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_OVERVIEW,
          },
          {
            title: 'All Users',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_LIST,
          },
          {
            title: 'Add a User',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_ADD,
          },
          {
            title: 'User Events',
            selected: true,
            link: DASHBOARD_ROUTES.ROUTE_USER_LOSS_EVENTS,
          },
        ]}
      />

      <div className="nv-container">
        <div className="section mb-10">
          <h2 className="title">User Events</h2>
          <hr />
          <div className="table">
            <div className="row header">
              <div className="column rank">#</div>
              <div className="column username">Employee/User Name</div>
              <div className="column employees">Company</div>
              <div className="column last-login">Category</div>
              <div className="column user-created">Created Date</div>
            </div>
            {allLossEvents.map((event: EventInstance) => {
              return (
                <a
                  onClick={() => {
                    handleNavigateToDetail(
                      DASHBOARD_ROUTES.ROUTE_USER_LOSS_EVENTS,
                      DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(event.userId),
                    );
                  }}
                  key={event.eventInstanceId}
                  className="row cursor-pointer"
                >
                  <div className="column rank">{event.rank}.</div>
                  <div className="column username">{event.userName}</div>
                  <div className="column employees">
                    {event.companyName ? event.companyName : 'n/a'}
                  </div>
                  <div className="column last-login">{event.eventName}</div>
                  <div className="column user-created">
                    {event.createdDate
                      ? moment(event.createdDate).format('LL')
                      : 'n/a'}
                  </div>
                </a>
              );
            })}

            <div className="nv-pagination">
              <div
                className={
                  'flex cursor-pointer ' + renderClassDisablePrevious()
                }
                onClick={() => handlePreviousPage()}
              >
                <div
                  className="mr-2"
                  dangerouslySetInnerHTML={renderIconPrevious()}
                />{' '}
                Previous
              </div>

              <div className="current">Page {pager.currentPage}</div>

              <div
                className={'flex cursor-pointer ' + renderClassDisableNext()}
                onClick={() => handleNextPage()}
              >
                Next{' '}
                <div
                  className="ml-2"
                  dangerouslySetInnerHTML={renderIconNext()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LossEvents;
