import React, { useEffect, useState } from 'react';
import { HeaderPage } from '../../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../../Routes';
import { Link, useParams } from 'react-router-dom';
import { UserInfo } from 'data/models/user.model';
import { selectUser } from 'store/userSlide';
import { useSelector } from 'react-redux';
import { EventInstance, EventQuestion } from 'data/models/loss-event.model';
import { getEventInstance } from 'data/api/event.api';
import moment from 'moment';

const UserLossEventDetail = () => {
  const userInfo: UserInfo = useSelector(selectUser);
  const [eventInstance, setEventInstance] = useState<EventInstance>();
  const [questions, setQuestions] = useState<EventQuestion[]>();
  const { loss_event_id } = useParams();

  useEffect(() => {
    if (loss_event_id) {
      void fetchEventInstance(loss_event_id);
    }
  }, [loss_event_id]);

  const fetchEventInstance = async (eventInstanceId: string) => {
    await getEventInstance(eventInstanceId)
      .then(([instance, questions]) => {
        setEventInstance(instance);
        setQuestions(questions);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <HeaderPage
        title={{
          title: `${userInfo.firstName} ${userInfo.lastName}`,
          subtitle: 'User Profile',
          hasBack: true,
          backLink: DASHBOARD_ROUTES.ROUTE_USER_OVERVIEW,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.USER_DETAIL_ID(userInfo.userId),
          },
          {
            title: 'User Notes',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId),
          },
          {
            title: 'User Events',
            selected: true,
            link: DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId),
          },
        ]}
      />

      <div className="nv-container">
        <div className="section">
          <div className="all-events">
            <Link to={DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId)}>
              ← {userInfo.firstName}&apos;s User Events
            </Link>
          </div>

          <h2 className="title">User Event Q&A ({eventInstance?.eventName})</h2>
          <hr />

          <div className="two-column">
            <div className="float large-left">
              {questions &&
                questions.map((question) => {
                  const text = question.valueText
                    ? question.valueText
                    : question.valueBoolean === true
                    ? 'Yes'
                    : question.valueBoolean === false
                    ? 'No'
                    : question.valueBoolean === undefined &&
                      question.type === 'nullable_boolean'
                    ? "I'm not sure"
                    : question.valueDate
                    ? question.valueText
                    : undefined;

                  return (
                    <div key={question.eventQuestionId}>
                      <div className="category">{question.questionText}</div>
                      <div className={text ? 'answer' : 'answer none'}>
                        {text ?? 'No answer'}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="section stats mb-10">
          <h2 className="title fields">User Event Details</h2>

          <div className="table">
            <div className="row">
              <div className="column header stat">Created Date</div>
              <div className="column stat-value">
                {moment(eventInstance?.createdDate).format('M/D/yy, h:mm a')}
              </div>
            </div>
            <div className="row">
              <div className="column header stat">Last Updated Date</div>
              <div className="column stat-value">
                {moment(eventInstance?.lastUpdateDate).format('M/D/yy, h:mm a')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserLossEventDetail;
