import { AxiosResponse } from 'axios';
import { API_ROUTES } from '../ApiRoutes';
import instance, { GET } from '../services/api';
import {
  CompaniesEmployeesPagination,
  CompanyEmployeeCreate,
  CompanyEmployeeInfo,
} from '../models/company-employee.model';
import { IListRequest } from 'data/models/common.model';
import { buildApiPath } from 'utils';
import { GridSortDirection } from '@mui/x-data-grid';

export interface ICompanyUserListRequest {
  query?: string;
  page: number;
  pageSize: number;
  sortBy?: string;
  order?: GridSortDirection;

  isAdmin?: string[];
  startDate?: string;
  endDate?: string;
  isActive?: string[];
}

export const getCompaniesEmployeesPagination = async (
  companyId: string,
  adminOnly: boolean,
  page: number,
  sortBy?: string,
  order?: string,
  pageSize?: number,
): Promise<CompaniesEmployeesPagination> => {
  const data: AxiosResponse<CompaniesEmployeesPagination> = await instance
    .get(
      API_ROUTES.companiesEmployees +
        `?companyId=${companyId}&adminOnly=${adminOnly}&page=${page.toString()}&sortBy=${sortBy}&order=${order}&pageSize=${
          pageSize || 10
        }`,
    )
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const createCompanyEmployee = async (
  payload: CompanyEmployeeCreate,
): Promise<CompanyEmployeeInfo> => {
  const data: AxiosResponse<CompanyEmployeeInfo> = await instance
    .post(API_ROUTES.companiesEmployees, payload)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const uploadEmployeesCSV = async (
  companyId: string,
  file: File,
): Promise<{ success: boolean; message: string }> => {
  const formData = new FormData();
  formData.append('file', file);

  const data: AxiosResponse<{ success: boolean; message: string }> =
    await instance
      .post(API_ROUTES.companiesEmployeesCSVCompanyId(companyId), formData, {
        headers: {
          'Content-Type': file.type,
        },
      })
      .catch((error) => {
        throw error;
      });

  return data.data;
};

export const getCompanyUsers = async (
  companyId: string,
  params: ICompanyUserListRequest,
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await GET(
      buildApiPath({
        path: API_ROUTES.getCompaniesUsers(companyId),
        params: params,
      }),
    );
    return response.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};
