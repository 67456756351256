/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import { ISelectOption } from 'data/constants/static';
import { X } from '@mui/icons-material';

type TSelectProps = MuiSelectProps & {
  data: ISelectOption[];
  control?: any;
  rules?: { [key: string]: string };
  name?: `${string}` | `${string}.${string}` | `${string}.${number}`;
  labelClassName?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: ReactNode;
  value?: any[];
};
const Placeholder = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        color: '#aaa',
      }}
    >
      {children}
    </div>
  );
};
const CustomSelect = ({
  data,
  label,
  labelClassName = '!bg-white !px-2 !-ml-2',
  error = false,
  helperText = '',
  value = [],
  ...props
}: TSelectProps) => {
  return (
    <FormControl fullWidth error={error}>
      <InputLabel
        shrink
        className={labelClassName}
        required={props.required}
        sx={{
          '& .MuiFormLabel-asterisk': {
            color: 'red',
          },
          color: '#213B54',
        }}
      >
        {label}
      </InputLabel>

      <MuiSelect
        className="!h-14"
        sx={{
          color: value ? '#1D1B20' : '#1D1B205D',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: error ? '#B3261E !important' : '#79747E !important',
          },
        }}
        multiple
        displayEmpty
        value={value}
        renderValue={(selected) => {
          const items = selected as string[];
          if (!items.length)
            return <Placeholder>{`Choose ${label}`}</Placeholder>;
          if (items.length === data.length) {
            return 'All';
          }
          return items.filter((str) => str).join(', ');
        }}
        {...props}
      >
        {data.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox
                checked={
                  (value as string[]).indexOf(option.value) > -1 ? true : false
                }
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          );
        })}
      </MuiSelect>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const MultiSelect = ({ control, name, rules = {}, ...props }: TSelectProps) => {
  if (control && name && rules) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={props.defaultValue || []}
        render={({ field: { ref, onChange, ...restField }, fieldState }) => {
          const value =
            restField.value !== undefined
              ? restField.value
              : props.defaultValue;
          let previousValue = [...value];

          const wrapperOnChange = (vals: any) => {
            const values = vals.target.value || [];
            if (!props.data.find((f) => f.value === '')) {
              onChange(vals);
              return;
            }
            if (!values.includes('')) {
              if (previousValue.includes('')) {
                vals.target.value = [];
                previousValue = [];
                onChange(vals);
              } else {
                const diff = props.data.filter(
                  (f) => f.value && !values.includes(f.value),
                );
                if (!diff.length) {
                  vals.target.value.push('');
                }
                previousValue = [...vals.target.value];
                onChange(vals);
              }
            } else {
              if (previousValue.includes('')) {
                if (values.includes('')) {
                  vals.target.value = values.filter((f: string) => f);
                  previousValue = [...vals.target.value];
                  onChange(vals);
                  return;
                }
              } else {
                vals.target.value = props.data.map((f) => f.value);
                previousValue = [...vals.target.value];
                onChange(vals);
                return;
              }
            }
          };
          return (
            <CustomSelect
              {...restField}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              {...props}
              value={value}
              onChange={wrapperOnChange}
              input={<OutlinedInput label={props.label} />}
            />
          );
        }}
      />
    );
  }
  return <CustomSelect {...props} />;
};

export default MultiSelect;
