import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveUser, clearUser } from '../../../store/userSlide';
import { getUserDetail } from '../../../data/api/user.api';

export const UserDetailLayout: React.FC = () => {
  const { user_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user_id) {
      void fetchUserDetail(user_id);
    }

    return () => {
      dispatch(clearUser());
    };
  }, [user_id]);

  const fetchUserDetail = async (userId: string) => {
    await getUserDetail(userId)
      .then((resp) => {
        dispatch(saveUser(resp.user));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="UserDetailLayout">
        <Outlet />
      </div>
    </>
  );
};
