export interface ISelectOption {
  value: any;
  label: string;
}
export const PROVIDERTYPES: string[] = [
  'Coach or Doula',
  'Therapist',
  'Psychiatrist',
];
export const GENDERS: string[] = [
  'Female',
  'Male',
  'Non-binary',
  'Transgender',
];
export const MODALITIES: string[] = [
  'Acceptance & Commitment (ACT)',
  'Cognitive Behavioral (CBT)',
  'Dialectical Behavioral (DBT)',
  'Mindfulness-Based (MBCT)',
  'Motivational Interviewing',
  'Psychodynamic',
  'Solution Focused Brief (SFBT)',
  'Strength-Based',
  'Trauma Focused',
  'Compassionate Bereavement Care',
  'Emotion Focused Therapy (EFT)',
  'Eye Movement Desensitization and Reprocessing (EMDR)',
  'Focused on practicing skills',
  'Gottman Method',
  'Grief Informed Therapy',
  'Health at Every Size (HAES)/Intuitive Eating (IE)',
  'Internal Family Systems Therapy (IFS)',
  'Art Therapy',
  'Practices from an anti-racist lens',
  'Somatic Experiencing (SE)',
];
export const INSURANCE: string[] = [
  `ACI Specialty Benefits`,
  `Aetna`,
  `Ambetter`,
  `American Behavioral`,
  `Amerigroup`,
  `Anthem | Elevance`,
  `APS Healthcare`,
  `Beacon Health Options | Carelon`,
  `Beech Street`,
  `BHS | Behavioral Health Systems`,
  `Blue Cross`,
  `Blue Shield`,
  `BlueCross BlueShield`,
  `Cenpatico`,
  `Ceridian`,
  `CHAMPVA`,
  `CHIPP | Children's Program`,
  `ChoiceCare Network`,
  `Cigna and Evernorth`,
  `ComPsych`,
  `Deek Oaks`,
  `ESI Employee Assistance Group`,
  `Friday Health Plans`,
  `GEHA`,
  `Great-West Life`,
  `Guardian`,
  `Health Net | MHN`,
  `Horizon Healthcare`,
  `Humana`,
  `Magellan`,
  `Medicaid`,
  `Medicare`,
  `MHNet Behavorial Health`,
  `Military OneSource`,
  `Molina Healthcare`,
  `MultiPlan`,
  `Optum`,
  `Oscar Health`,
  `Out-of-network`,
  `PHCS Network`,
  `Scott & White Health Plan`,
  `Superior HealthPlan`,
  `Telus | LifeWorks`,
  `TRICARE`,
  `Triwest`,
  `UMR`,
  `UnitedHealthcare UHC | UBH`,
  `VA Community Care Network (CCN)`,
];
export interface State {
  name: string;
  abbreviation: string;
}

export const STATES: State[] = [
  {
    name: 'All',
    abbreviation: '',
  },
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];
export const LANGUAGES: string[] = [
  'Albanian',
  'American Sign Language',
  'Arabic',
  'Armenian',
  'Assamese',
  'Bengali',
  'Bulgarian',
  'Cantonese',
  'Danish',
  'Dutc',
  'English',
  'Farsi',
  'Flemish',
  'French',
  'Fuzhounese',
  'German',
  'Greek',
  'Gujarati',
  'Haitian',
  'Creole',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Icelandic',
  'IgboItalian',
  'Japanese',
  'Kashmiri',
  'Korean',
  'Kurdish',
  'Malayalam',
  'Mandarin',
  'Marathi',
  'Norwegian',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Romanian',
  'Russian',
  'Shona',
  'Somali',
  'Spanish',
  'Swahili',
  'Tagalog',
  'Turkish',
  'Twi',
  'Ukrainian',
  'Urdu',
  'Vietnamese',
  'Yiddish',
  'Yoruba',
];

export const ETHNICITY: string[] = [
  'Asian',
  'Black or African descent',
  'Caucasian',
  'Hispanic',
  'Latinx',
  'Native American,Pacific Islander',
  'South Asian',
  'Other Racial or Ethnic Background',
];

export const PROVIDER_STATES: State[] = [
  {
    name: 'All',
    abbreviation: '',
  },
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];
