import { AxiosResponse } from 'axios';
import { API_ROUTES } from '../ApiRoutes';
import instance from '../services/api';
import { NoteInfo, NoteUserCreate, UserNotes } from '../models/user-note.model';

export const getUserNotes = async (
  userId: string,
  includeAuto: boolean,
): Promise<UserNotes> => {
  const data: AxiosResponse<UserNotes> = await instance
    .get(API_ROUTES.adminUserNotes(userId) + `?includeAuto=${includeAuto}`)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const createUserNote = async (
  userId: string,
  payload: NoteUserCreate,
): Promise<NoteInfo> => {
  const data: AxiosResponse<NoteInfo> = await instance
    .post(API_ROUTES.adminUserNotes(userId), payload)
    .catch((error) => {
      throw error;
    });

  return data.data;
};
