import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { CompanyInfo } from '../data/models/company.model';

export interface CompanyState {
  value: CompanyInfo;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: CompanyState = {
  value: {
    companyId: '',
    name: '',
    policyDescription: '',
    logo: '',
    userCount: 0,
    defaultState: '',
    hasEmployees: false,
    hasNonEmployees: false,
  },
  status: 'idle',
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    saveCompany: (state, action: PayloadAction<CompanyInfo>) => {
      state.value = action.payload;
    },
    clearCompany: (state) => {
      state.value = {
        companyId: '',
        name: '',
        policyDescription: '',
        logo: '',
        userCount: 0,
        defaultState: '',
        hasEmployees: false,
        hasNonEmployees: false,
      };
    },
  },
});

export const { saveCompany, clearCompany } = companySlice.actions;
export const selectCompany = (state: RootState) => state.company.value;
export default companySlice.reducer;
