import React, { useState } from 'react';
import { HeaderPage } from '../../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../../Routes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UserInfo } from '../../../../data/models/user.model';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../store/userSlide';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { createUserNote } from '../../../../data/api/user-note.api';

const schema = yup
  .object({
    note: yup.string().required('Note is required.'),
  })
  .required();

const UserNoteAdd = () => {
  const { user_id } = useParams();
  const userInfo: UserInfo = useSelector(selectUser);

  const [isSubmitting, setIsSubmitting] = useState(true);
  const { register, formState, getValues } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { isValid, errors } = formState;
  const navigate = useNavigate();

  const handleCreateUserNote = async () => {
    if (isSubmitting && user_id) {
      setIsSubmitting(false);

      const { note } = getValues();
      await createUserNote(user_id, { note })
        .then(() => {
          toast.success('Create user note successfully!');
          navigate(DASHBOARD_ROUTES.USER_ID_NOTES(user_id));
        })
        .catch((error) => {
          console.error(error);
          toast.error('Create user note fail!');
        })
        .finally(() => {
          setIsSubmitting(true);
        });
    }
  };

  return (
    <>
      <HeaderPage
        title={{
          title:
            userInfo?.firstName || userInfo?.lastName
              ? userInfo?.firstName + ' ' + userInfo?.lastName
              : '',
          subtitle: 'User Profile',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.USER_DETAIL_ID(userInfo.userId),
          },
          {
            title: 'User Notes',
            selected: true,
            link: DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId),
          },
          {
            title: 'User Events',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId),
          },
        ]}
      />

      <div className="nv-container">
        <div className="section notes mb-10">
          <div className="tabs">
            <Link
              to={DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId)}
              className="tab"
            >
              Internal Notes
            </Link>
            <Link
              to={DASHBOARD_ROUTES.USER_ID_NOTES_ADD(userInfo.userId)}
              className="tab selected"
            >
              Add Note
            </Link>
          </div>

          <hr />

          <div className="filter">
            <label>Leave a new internal note.</label>
          </div>

          <div id="add-note">
            <textarea className="resize-y" {...register('note')} />
            <span className="text-red-100 text-sm py-2">
              {errors.note?.message}
            </span>
            <div className="buttons mt-4">
              <input
                disabled={!isValid}
                className={!isValid ? 'opacity-50 cursor-not-allowed' : ''}
                type="submit"
                value="Add Note"
                onClick={() => handleCreateUserNote()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserNoteAdd;
