import { ICompanyTrackingRoot } from '@data/models/company-tracking.model';
import { AxiosResponse } from 'axios';
import { API_ROUTES } from '../ApiRoutes';
import instance from '../services/api';

import { GridSortDirection } from '@mui/x-data-grid';
import { buildApiPath } from 'utils';
interface IListRequest {
  query?: string;
  page: number;
  pageSize: number;
  sortBy?: string;
  order?: GridSortDirection;

  contentType?: string[];
  startDate?: string;
  endDate?: string;
  title?: string;
}

export const getCompanyTracking = async (
  params: IListRequest,
  companyId?: string,
) => {
  try {
    const data: AxiosResponse<ICompanyTrackingRoot> = await instance.get(
      buildApiPath({
        path: API_ROUTES.companiesTracking(companyId || ''),
        params: params || null,
      }),
    );
    return data.data;
  } catch (error: unknown) {
    if (typeof error === 'string') {
      throw Error(error.toUpperCase());
    } else if (error instanceof Error) {
      throw Error(error.message);
    }
    throw Error('');
  }
};
