import {
  getMigrationEvents,
  getTaskInfo,
  postMigrationEvents,
} from '../../data/api/event.api';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import _ from 'lodash';
interface IItem {
  userEventId: string;
  userId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  eventTypeId: string;
  eventTypeName?: string;
  companyName?: string;
  companyId?: string;
  events?: {
    id: string;
    name: string;
    chosen: boolean;
    createDate?: Date;
    relatedRoleRelationship?: string;
    relatedRoleName?: string;
  }[];
  relatedRoleRelationship?: string;
  relatedRoleName?: string;
  checked: boolean;
  createDate?: Date;
  id?: number;
}
interface ITaskInfo {
  id: string;
  taskName: string;
  taskStatus: string;
}
interface ISimpleListTaskProps {
  data: ITaskInfo[];
  open: boolean;
  onClose: () => void;
  title: string;
}
interface ISummaryDialogProps {
  data: IItem[];
  open: boolean;
  onAccept: () => Promise<void>;
  onClose: () => void;
  loading: boolean;
}

function SimpleDialog(props: Readonly<ISimpleListTaskProps>) {
  const { data, onClose, open, title } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {data?.map((dt) => (
          <ListItem disableGutters key={dt.id}>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <TaskOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={dt.taskName} secondary={dt.taskStatus} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

function SummaryTable({ data }: Readonly<{ data: IItem[] }>) {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, maxHeight: 650 }}
        aria-label="simple table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Member</TableCell>
            <TableCell>Member Event</TableCell>
            <TableCell>Member Event ID</TableCell>
            <TableCell>Event Created By Admin for Member</TableCell>
            <TableCell>Event Created By Admin for Member ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .filter((f) => f.checked)
            .map((row) => (
              <TableRow
                key={row.userEventId}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography
                    fontWeight={'bold'}
                    fontSize={'15px'}
                    lineHeight={'24px'}
                    sx={{
                      color: '#F29765',
                      display: '-webkit-box',
                      lineClamp: 2,
                      overflow: 'hidden',
                      whiteSpace: 'pre-wrap',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {`${row.companyName}`}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Tooltip title={row.email}>
                    <Typography>{`${row.firstName} ${row.lastName}`}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography>{`${row.eventTypeName}`}</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography>
                    {row.userEventId ? `${row.userEventId}` : ''}
                  </Typography>
                </TableCell>

                <TableCell component="th" scope="row">
                  <Typography>{`${
                    (row?.events?.find((f) => f.chosen) ?? row?.events?.[0])?.id
                  }`}</Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function SummaryDialog(props: Readonly<ISummaryDialogProps>) {
  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{'Please double check the data'}</Box>
          <Box>
            <IconButton onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <SummaryTable data={props.data} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} autoFocus disabled={props.loading}>
          Disagree
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={async () => {
            await props.onAccept();
          }}
          disabled={props.loading}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const MigrationPage = () => {
  const [data, setData] = useState<IItem[]>([]);
  const [tasks, setTasks] = useState<ITaskInfo[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [taskListTitle, setTaskListTitle] = useState<string>('');
  const [openSummary, setOpenSummary] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const onCloseSummary = () => {
    setOpenSummary(false);
  };
  const onAcceptSummary = async () => {
    const requests: any[] = [];
    let removableAdminEvents: any[] = [];
    for (const item of data.filter((f) => f.checked)) {
      if (!item.events?.length) {
        const tmp = {
          customerEventId: item?.userEventId,
          adminEventId: '',
          userId: item?.userId,
        };
        requests.push(tmp);
      } else {
        const event = item.events?.find((f) => f.chosen) ?? item.events[0];
        const tmp = {
          customerEventId: item?.userEventId,
          adminEventId: event.id,
          userId: item?.userId,
        };
        const rmIds = item.events
          ?.filter((f) => !f.chosen && f.id !== event.id)
          .map((f) => f.id)
          .filter(Boolean);
        removableAdminEvents = [...removableAdminEvents, ...rmIds];
        requests.push(tmp);
      }
    }
    removableAdminEvents = _.uniq(removableAdminEvents);
    setLoading(true);
    try {
      await postMigrationEvents({ requests, removableAdminEvents });
      toast.success('Migration event successfully!');
      setData([]);
      await fetchData();
    } catch (error) {
      toast.error('Cannot migration data');
    } finally {
      setOpenSummary(false);
      setLoading(false);
    }
    // if (requests?.length > 1) {
    //   toast.error(`Just choose one please`);
    //   return;
    // } else {
    //   removableAdminEvents = _.uniq(removableAdminEvents);
    //   setLoading(true);
    //   try {
    //     await postMigrationEvents({ requests, removableAdminEvents });
    //     toast.success('Migration event successfully!');
    //     setData([]);
    //     await fetchData();
    //   } catch (error) {
    //     toast.error('Cannot migration data');
    //   } finally {
    //     setOpenSummary(false);
    //     setLoading(false);
    //   }
    // }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getMigrationEvents();
      if (res) {
        for (const item of res) {
          item.events = [...(item?.events || [])];
        }
        setData(res);
      }
    } catch (error) {
      toast.error('Cannot load migrations list');
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchTask = async (eventId: string) => {
    try {
      if (eventId) {
        const res = await getTaskInfo(eventId);
        if (res) {
          setTasks(res);
          return true;
        }
      }
      setTasks([]);
      return false;
    } catch (error) {
      toast.error('Cannot load migrations list');
      setData([]);
      return false;
    }
  };
  const handleChange = (id: number, chosenId: string) => {
    for (const item of data.filter((f) => f.id === id)) {
      if (item.events) {
        for (const event of item.events) {
          event.chosen = event?.id === chosenId;
        }
      }
    }
    setData([...data]);
  };

  const openModalTaskDetail = async (userEventId: string) => {
    const res = await fetchTask(userEventId);
    if (res) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setTasks([]);
    }, 100);
  };

  const handleCheckChange = (id: number, checked: boolean) => {
    for (const element of data.filter((f) => f.id === id)) {
      element.checked = checked;
    }

    setData([...data]);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ my: 5 }} maxWidth="xxl">
        <Stack direction={'row'} justifyContent={'space-between'}>
          <h2 className="mb-3">MIGRATION EVENTS</h2>
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={() => setOpenSummary(true)}
          >
            Migration Events
          </Button>
        </Stack>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, maxHeight: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Company</TableCell>
                <TableCell>Member</TableCell>
                <TableCell>Member Event</TableCell>
                <TableCell>Event Created By Admin for Member</TableCell>
                <TableCell>Warning</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {
                const totalEvents = row?.events?.length || 0;
                if (row.userEventId) {
                  data.find((f) => {
                    return (
                      f.relatedRoleRelationship === row.relatedRoleRelationship
                    );
                  });
                }
                return (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      backgroundColor: !row.userEventId
                        ? ''
                        : totalEvents > 1 &&
                          data.filter((f) => {
                            return (
                              f.relatedRoleRelationship ===
                                row.relatedRoleRelationship &&
                              f.eventTypeName === row.eventTypeName &&
                              f.userId === row.userId
                            );
                          }).length > 1
                        ? 'rgb(254 249 195)'
                        : '',
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Checkbox
                        checked={row.checked}
                        onChange={(event) => {
                          handleCheckChange(row?.id ?? 0, event.target.checked);
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <a
                        href={`/company/detail/${row.companyId}`}
                        target="_blank"
                        rel="noreferrer"
                        className="no-underline hover:underline"
                      >
                        <Typography
                          fontWeight={'bold'}
                          fontSize={'15px'}
                          lineHeight={'24px'}
                          sx={{
                            color: '#F29765',
                            display: '-webkit-box',
                            lineClamp: 2,
                            overflow: 'hidden',
                            whiteSpace: 'pre-wrap',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {`${row.companyName}`}
                        </Typography>
                      </a>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Tooltip title={row.email}>
                        <Typography>{`${row.firstName} ${row.lastName}`}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <FormControl fullWidth>
                        <Stack direction="row">
                          <Tooltip title="Click to open task list">
                            <Typography
                              sx={{ cursor: 'pointer' }}
                              className="no-underline hover:underline"
                              onClick={async () => {
                                if (row.userEventId) {
                                  setTaskListTitle(
                                    `CUSTOMER USER EVENT ID: ${
                                      row.userEventId || ''
                                    }`,
                                  );
                                  await openModalTaskDetail(row.userEventId);
                                }
                              }}
                            >
                              {row.userEventId &&
                                `${[
                                  row.eventTypeName,
                                  row.relatedRoleRelationship === 'Unknown'
                                    ? ''
                                    : row.relatedRoleRelationship,
                                  row.relatedRoleName,
                                  row.createDate
                                    ? moment(row.createDate).format(
                                        'MM/DD/YYYY HH:mm:ss',
                                      )
                                    : '',
                                ]
                                  .filter(Boolean)
                                  .join('-')}`}
                            </Typography>
                          </Tooltip>
                        </Stack>
                      </FormControl>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Stack direction="row">
                        <FormControl fullWidth>
                          <Select
                            size="small"
                            value={
                              (
                                row?.events?.find((f) => f.chosen) ??
                                row?.events?.[0]
                              )?.id
                            }
                            onChange={(ev: SelectChangeEvent) => {
                              handleChange(row.id ?? 0, ev.target.value || '');
                            }}
                            disabled={!row.events?.length}
                          >
                            {row.events?.map((ev) => (
                              <MenuItem key={ev.id} value={ev.id}>
                                {`${[
                                  ev.name,
                                  ev.relatedRoleRelationship === 'Unknown'
                                    ? ''
                                    : ev.relatedRoleRelationship,
                                  ev.relatedRoleName,
                                  ev.createDate
                                    ? moment(ev.createDate).format(
                                        'MM/DD/YYYY HH:mm:ss',
                                      )
                                    : '',
                                ]
                                  .filter(Boolean)
                                  .join('-')}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          variant="contained"
                          disabled={!row.events?.length}
                          onClick={async () => {
                            const chosenId =
                              (
                                row?.events?.find((f) => f.chosen) ??
                                row?.events?.[0]
                              )?.id ?? '';
                            setTaskListTitle(
                              `ADMIN USER EVENT ID: ${chosenId}`,
                            );
                            await openModalTaskDetail(chosenId);
                          }}
                        >
                          Detail
                        </Button>
                      </Stack>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {!row.userEventId ? (
                        <CheckCircleOutlinedIcon />
                      ) : totalEvents > 1 &&
                        data.filter((f) => {
                          return (
                            f.relatedRoleRelationship ===
                              row.relatedRoleRelationship &&
                            f.eventTypeName === row.eventTypeName &&
                            f.userId === row.userId
                          );
                        }).length > 1 ? (
                        <WarningAmberOutlinedIcon />
                      ) : (
                        <CheckCircleOutlinedIcon />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <SimpleDialog
          open={open}
          onClose={handleClose}
          data={tasks}
          title={taskListTitle}
        />
        <SummaryDialog
          loading={loading}
          data={data}
          open={openSummary}
          onAccept={onAcceptSummary}
          onClose={onCloseSummary}
        />
      </Container>
    </>
  );
};
export default MigrationPage;
