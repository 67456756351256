import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { getCompanyDetail } from '../../../data/api/company.api';
import { useDispatch } from 'react-redux';
import { clearCompany, saveCompany } from '../../../store/companySlide';

export const CompanyDetailLayout: React.FC = () => {
  const { company_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (company_id) {
      void fetchCompanyDetail(company_id);
    }

    return () => {
      dispatch(clearCompany());
    };
  }, [company_id]);

  const fetchCompanyDetail = async (companyId: string) => {
    await getCompanyDetail(companyId)
      .then((resp) => {
        dispatch(saveCompany(resp.company));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="CompanyDetailLayout">
        <Outlet />
      </div>
    </>
  );
};
