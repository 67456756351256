import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderPage } from '../../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../../Routes';
import { saveNavigateLinkHeader } from '../../../../store/navigateSlide';
import { useDispatch } from 'react-redux';
import SearchUser from '../../components/search-user/SearchUser';
import {
  User,
  Pagination,
  UsersPagination,
} from '../../../../data/models/user.model';
import { getUsersPagination } from '../../../../data/api/user.api';
import moment from 'moment';

const UsersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [users, setUsers] = useState<User[]>([]);
  const [pager, setPager] = useState<Pagination>({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 30,
    isLastPage: true,
    itemsOnThisPage: 0,
  });
  const [pageQuery, setPageQuery] = useState<number>(1);

  useEffect(() => {
    void fetchUsersList();
  }, [pageQuery]);
  const fetchUsersList = async () => {
    const data: UsersPagination = await getUsersPagination(pageQuery);
    setUsers(data.users);
    setPager(data.pager);
  };

  const renderIconNext = () => {
    return { __html: '&rarr;' };
  };

  const renderIconPrevious = () => {
    return { __html: '&larr;' };
  };

  const renderClassDisablePrevious = () => {
    if (pager.currentPage === 1) {
      return 'text-[#999] cursor-not-allowed';
    }
    return '';
  };

  const renderClassDisableNext = () => {
    if (pager.isLastPage) {
      return 'text-[#999] cursor-not-allowed';
    }
    return '';
  };

  const handlePreviousPage = () => {
    if (pager.currentPage === 1) {
      return;
    }
    setPageQuery(pageQuery - 1);
  };

  const handleNextPage = () => {
    if (pager.isLastPage) {
      return;
    }
    setPageQuery(pageQuery + 1);
  };

  const handleNavigateToDetail = (fromLink: string, toLink: string) => {
    dispatch(saveNavigateLinkHeader({ fromLink, toLink }));
    navigate(toLink);
  };

  return (
    <>
      <HeaderPage
        title={{ title: 'Users' }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_OVERVIEW,
          },
          {
            title: 'All Users',
            selected: true,
            link: DASHBOARD_ROUTES.ROUTE_USER_LIST,
          },
          {
            title: 'Add a User',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_ADD,
          },
          {
            title: 'User Events',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_LOSS_EVENTS,
          },
        ]}
      />

      <div className="nv-container">
        <div className="section">
          <SearchUser linkCurrent={DASHBOARD_ROUTES.ROUTE_USER_LIST} />
        </div>

        <div className="section mb-10">
          <h2 className="title">Users List</h2>
          <hr />
          <div className="table">
            <div className="row header">
              <div className="column rank">#</div>
              <div className="column username">Name</div>
              <div className="column employees">Company</div>
              <div className="column last-login">Last Login Date</div>
              <div className="column user-created">Created Date</div>
            </div>
            {users.map((user: User) => {
              return (
                <a
                  onClick={() => {
                    handleNavigateToDetail(
                      DASHBOARD_ROUTES.ROUTE_USER_LIST,
                      DASHBOARD_ROUTES.USER_DETAIL_ID(user.userId),
                    );
                  }}
                  key={user.userId}
                  className="row cursor-pointer"
                >
                  <div className="column rank">{user.rank}.</div>
                  <div className="column username">{user.name}</div>
                  <div className="column employees">
                    {user.companyName ? user.companyName : 'n/a'}
                  </div>
                  <div className="column last-login">
                    {user.lastLoginDate ? (
                      moment(user.lastLoginDate).format('LL')
                    ) : (
                      <div>n/a</div>
                    )}
                  </div>
                  <div className="column user-created">
                    {user.createdDate ? (
                      moment(user.createdDate).format('LL')
                    ) : (
                      <div>n/a</div>
                    )}
                  </div>
                </a>
              );
            })}

            <div className="nv-pagination">
              <div
                className={
                  'flex cursor-pointer ' + renderClassDisablePrevious()
                }
                onClick={() => handlePreviousPage()}
              >
                <div
                  className="mr-2"
                  dangerouslySetInnerHTML={renderIconPrevious()}
                />{' '}
                Previous
              </div>

              <div className="current">Page {pager.currentPage}</div>

              <div
                className={'flex cursor-pointer ' + renderClassDisableNext()}
                onClick={() => handleNextPage()}
              >
                Next{' '}
                <div
                  className="ml-2"
                  dangerouslySetInnerHTML={renderIconNext()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UsersList;
