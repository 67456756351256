export const API_ROUTES = {
  oauth2: 'sessions/oauth2',
  googleSSO: 'admin/sessions/sso',

  // User
  users: 'users',

  // Company
  companiesOverview: 'admin/companies/overview',
  companiesSearch: 'admin/companies/search',
  companies: 'admin/companies',
  company: (id: string) => `admin/companies/${id}`,
  companyLinks: (id: string) => `admin/companies/${id}/links`,

  // Company Note
  companiesNotes: (companyId: string) => `admin/companies/${companyId}/notes`,
  companiesNotesByNoteId: (companyId: string, companyNoteId: string) =>
    `admin/companies/${companyId}/notes/${companyNoteId}`,

  // Company Employee
  companiesEmployees: 'admin/users',
  companiesEmployeesCSVCompanyId: (companyId: string) =>
    `admin/users/csv/${companyId}`,

  // Company Tracking
  companiesTracking: (companyId: string) =>
    `admin/user-usage-log/company/${companyId}`,
  companiesTrackingExportCSV: (companyId: string) =>
    `admin/user-usage-log/company/${companyId}/download`,

  // Admin User
  usersOverview: 'admin/users/overview',
  usersSearch: 'admin/users/search',
  adminUsers: 'admin/users',
  adminUser: (id: string) => `admin/users/${id}`,

  // Admin User Note
  adminUserNotes: (userId: string) => `admin/users/${userId}/notes`,
  adminUserNotesByNoteId: (userId: string, userNoteId: string) =>
    `admin/users/${userId}/notes/${userNoteId}`,

  // Admin User Tracking
  adminUserTracking: (userId: string) => `admin/user-usage-log/user/${userId}`,
  adminUsersExportCSV: (userId: string) =>
    `admin/user-usage-log/user/${userId}/download`,

  adminEventsInstances: 'admin/events/instances',
  adminEventsInstance: (eventInstanceId: string) =>
    `admin/events/instances/${eventInstanceId}`,

  //Provider
  providers: 'admin/providers',
  providerById: (providerId: string) => `admin/providers/${providerId}`,
  createProvider: `admin/providers/create`,
  editProvider: `admin/providers/edit`,
  adminProvider: (id: string) => `admin/providers/${id}`,

  //Template Task List
  templateTaskList: 'admin/templateTaskList/all',

  //templateTaskList
  templateTaskListById: (templateTaskListId: string) =>
    `admin/templateTaskList/${templateTaskListId}`,
  createTemplateTaskList: `admin/templateTaskList/create`,
  editTemplateTaskList: `admin/templateTaskList/edit`,

  //event
  getMemberEventList: `/userEvents/event-list`,

  //TemplateTask
  templateTask: 'admin/templateTask/all',

  //templateTask
  templateTaskById: (templateTaskId: string) =>
    `admin/templateTask/${templateTaskId}`,
  createTemplateTask: `admin/templateTask/create`,
  editTemplateTask: `admin/templateTask/edit`,

  //communication_templates
  getCommunicationTemplates: `/communicationTemplates/all`,

  deleteUser: (userId: string) => `admin/users/${userId}`,

  deleteCompany: (companyId: string) => `admin/companies/${companyId}`,

  companyLinkNonEmployeeSponsoredAccount: (id: string) =>
    `admin/companies/${id}/linksNonEmployeeSponsor`,

  //user checks-ins
  getUserCheckIns: (userId: string) => `admin/users/check-ins/${userId}`,
  exportUserCheckIns: (userId: string) =>
    `admin/users/check-ins/${userId}/download`,

  getCompaniesUsers: (companyId: string) => `admin/users/${companyId}/users`,

  getUserEvents: (userId: string) => `admin/users/${userId}/user-events`,

  companiesUsersExportCSV: (companyId: string) =>
    `admin/users/${companyId}/users/download`,

  migrationEvents: 'admin/events/migrations',
  migrationTaskInfo: 'admin/events/migrations/taskInfo',
};
