import React from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';

type TButtonProps = MuiButtonProps;

const Button = ({
  children,
  className = '!rounded-full !h-10 !py-0 !tracking-[0.1px] !shadow-none',
  ...props
}: TButtonProps) => {
  return (
    <MuiButton className={className} {...props}>
      {children}
    </MuiButton>
  );
};

export default Button;
